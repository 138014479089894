<template>
  <div class="flex h-full max-h-full flex-col gap-3">
    <div class="flex flex-shrink-0 flex-row justify-end gap-3">
      <FilterContainerComponent
        v-if="availableFilter"
        :count="countFilter ?? 0"
      >
        <template #filter-content="{ close }">
          <FilterLinesComponent
            v-model:filters="filters"
            :filters-available="availableFilter"
            @close-popover="handleClosePopover"
            @remove:item:line="updateStorage"
          />
        </template>
      </FilterContainerComponent>

      <div v-if="displayUpdateCategory" class="w-auto">
        <BaseButton
          icon="paper-plane"
          color="success"
          text-color="black"
          @click="updateCategories"
        ></BaseButton>
      </div>
      <div v-if="displaySearchBar" class="w-60">
        <BaseSimpleSearchInput v-model="searchText" group-class="mt-0" />
      </div>
      <div class="flex">
        <WalkmanButtonIcon
          v-model="displayMode"
          :options="[
            { value: 'list', icon: ['fal', 'list'] },
            { value: 'card', icon: ['fas', 'address-card'] }
          ]"
        />
      </div>
    </div>
    <div class="min-h-0 flex-1 overflow-scroll">
      <TheLoadingComponent v-if="!tableMetadata.columns" />
      <template v-else>
        <template v-if="displayMode === 'list'">
          <slot name="list">
            <div>
              <BaseTableComponent
                v-if="actions"
                v-model:sortColumn="sortColumn"
                v-model:sortType="sortType"
                v-model:currentSort="currentSort"
                v-model:sort-direction="sortDirection"
                v-model:categoryArray="categoryArray"
                v-model:displayUpdateButton="displayUpdateCategory"
                :columns="tableMetadata.columns"
                :data="filteredEntries"
                :actions="actions"
                :row-draggable="rowDraggable"
              >
                <template #table-cell="{ index, column, data }">
                  <slot
                    name="table-cell"
                    :index="index"
                    :column="column"
                    :data="data"
                  />
                </template>
              </BaseTableComponent>
            </div>
          </slot>
        </template>

        <template v-else-if="displayMode === 'card'">
          <slot name="cards">
            <div v-if="displayCardByGroup">
              <div
                v-for="(group, index) in filteredEntriesByGroup"
                :key="index"
                class="relative"
              >
                <div
                  class="box sticky top-0 rounded-lg bg-gray-200 px-3 py-2 text-xl font-medium capitalize"
                >
                  {{ index }}
                </div>
                <div
                  class="grid grid-cols-1 px-3 py-5 sm:gap-3 md:grid-cols-3 md:gap-5"
                >
                  <div v-for="entry in group" :key="entry">
                    <BaseCard
                      :entry="entry"
                      :title="baseCardTitle"
                      :title-icon="baseCardTitleIcon"
                      :subtitle="baseCardSubtitle"
                      :photo="baseCardPhoto"
                      :select-entry="selectEntry"
                      :actions="actions"
                    >
                      <template #card-avatar="{ entry: entryCard }">
                        <slot name="card-avatar" :entry="entryCard" />
                      </template>
                      <template #card-details="{ entry: entryCard }">
                        <slot name="card-details" :entry="entryCard" />
                      </template>
                    </BaseCard>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="sm: mt-8 grid grid-cols-1 p-3 sm:gap-3 md:grid-cols-3 md:gap-5"
            >
              <BaseCard
                v-for="entry in filteredEntries"
                :key="entry"
                :entry="entry"
                :title="baseCardTitle"
                :title-icon="baseCardTitleIcon"
                :subtitle="baseCardSubtitle"
                :photo="baseCardPhoto"
                :select-entry="selectEntry"
                :actions="actions"
              >
                <template #card-avatar="{ entry: entryCard }">
                  <slot name="card-avatar" :entry="entryCard" />
                </template>
                <template #card-details="{ entry: entryCard }">
                  <slot name="card-details" :entry="entryCard" />
                </template>
              </BaseCard>
            </div>
          </slot>
        </template>
      </template>
    </div>
    <div
      v-if="withPagination && entries.length > pageLength"
      class="flex-shrink-0"
    >
      <PaginationComponent
        v-model:current-page="currentPage"
        :entries-length="entries.length"
        :page-length="pageLength"
      />
    </div>
  </div>
</template>

<script>
import BaseCard from '@c/BaseCard'
import BaseTableComponent from '@c/BaseTableComponent'
import TheLoadingComponent from './TheLoadingComponent'
import multiselect from 'vue-multiselect'
import PaginationComponent from '@c/PaginationComponent.vue'
import FilterContainerComponent from '@c/Dashboard/Components/FilterContainerComponent.vue'
import DashboardPanelControl from '@c/Dashboard/Components/DashboardPanelControl.vue'
import FilterLinesComponent from '@c/Dashboard/Components/FilterLinesComponent.vue'
import BasePaginationMixin from '@u/mixins/Pagination/BasePaginationMixin'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import WalkmanButtonIcon from '@c/WalkmanButtonIcon.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'

export default {
  name: 'BaseTableCardsComponent',
  components: {
    BaseButton,
    WalkmanButtonIcon,
    FontAwesomeIcon,
    FilterLinesComponent,
    DashboardPanelControl,
    FilterContainerComponent,
    PaginationComponent,
    BaseTableComponent,
    BaseCard,
    TheLoadingComponent,
    multiselect
  },
  mixins: [BasePaginationMixin],
  props: {
    storageName: {
      type: String,
      required: false,
      default: undefined
    },
    baseDisplayMode: {
      type: String,
      required: false,
      default: 'list'
    },
    baseCardTitle: {
      type: String,
      required: true
    },
    baseCardTitleIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    baseCardSubtitle: {
      type: String,
      required: false,
      default: null
    },
    baseCardPhoto: {
      type: String,
      required: false,
      default: null
    },
    entries: {
      type: Array,
      required: true
    },
    tableMetadata: {
      type: Object,
      required: true
    },
    selectEntry: {
      type: Function,
      required: false,
      default: null
    },
    actions: {
      type: Array,
      required: false,
      default: null
    },
    displaySearchBar: {
      type: Boolean,
      required: false,
      default: true
    },
    displayCardByGroup: {
      type: Boolean,
      required: false,
      default: false
    },
    groupBy: {
      type: String,
      required: false,
      default: null
    },
    arrayGroupOptions: {
      type: [Object, Array],
      required: false,
      default: null
    },
    availableFilter: {
      type: [Object, Array],
      required: false,
      default: null
    },
    withPagination: {
      type: Boolean,
      required: false,
      default: false
    },
    rowDraggable: {
      type: Boolean,
      required: false,
      default: false
    },
    categoryArray: {
      type: Array,
      required: false,
      default: null
    }
  },
  emits: [
    'listDisplayMode',
    'cardsDisplayMode',
    'entriesFiltered',
    'changeBaseDisplayMode',
    'update:filters',
    'updateCategories'
  ],
  data() {
    const filters =
      this.storageName && localStorage.getItem(this.storageName)
        ? JSON.parse(localStorage.getItem(this.storageName))
        : []

    return {
      displayMode: this.baseDisplayMode,
      searchText: '',
      filters: filters,
      currentPage: 1,
      currentSort: '',
      sortType: '',
      sortColumn: {},
      sortDirection: '',
      displayUpdateCategory: false
    }
  },
  computed: {
    countFilter() {
      return this.filters?.length
    },
    pageLength() {
      return this.initialMetadata?.settings?.number_rows_by_page_list ?? 50
    },
    filteredEntries() {
      const searchTextLower = this.$h.removeAccents(
        this.searchText.toLowerCase()
      )
      let copyData = this.entries

      if (this.sortDirection && this.sortColumn) {
        copyData = copyData.sort((a, b) => {
          let fa, fb
          if (this.sortType && this.sortType === 'date') {
            fa = a[this.sortColumn]
              ? a[this.sortColumn]['sort_value']
              : this.sortDirection === 'up'
              ? '9999999999999999'
              : this.sortDirection === 'down'
              ? '00000000000000000'
              : ''
            fb = b[this.sortColumn]
              ? b[this.sortColumn]['sort_value']
              : this.sortDirection === 'up'
              ? '9999999999999999'
              : this.sortDirection === 'down'
              ? '00000000000000000'
              : ''
          } else {
            if (Array.isArray(a[this.sortColumn])) {
              fa = isNaN(a[this.sortColumn][0])
                ? a[this.sortColumn][0].toLowerCase()
                : a[this.sortColumn]
              fb = isNaN(b[this.sortColumn][0])
                ? b[this.sortColumn][0].toLowerCase()
                : b[this.sortColumn]
            } else {
              fa = isNaN(a[this.sortColumn])
                ? a[this.sortColumn].toLowerCase()
                : a[this.sortColumn]
              fb = isNaN(b[this.sortColumn])
                ? b[this.sortColumn].toLowerCase()
                : b[this.sortColumn]
            }
          }
          if (fa < fb) {
            return this.sortDirection === 'up' ? -1 : 1
          }
          if (fa > fb) {
            return this.sortDirection === 'up' ? 1 : -1
          }
          return 0
        })
      }
      return [...copyData]
        .filter(
          (element) =>
            this.searchText.length === 0 ||
            Object.values(element).some(
              (value) =>
                value &&
                this.$h
                  .removeAccents(value.toString().toLowerCase())
                  .includes(searchTextLower)
            )
        )
        .slice(
          this.withPagination ? (this.currentPage - 1) * this.pageLength : 0,
          this.withPagination ? this.currentPage * this.pageLength : undefined
        )
    },

    filteredEntriesByGroup() {
      if (!this.filteredEntries) {
        return []
      }

      let filteredByGroup = this.filteredEntries.reduce(
        (group_entries, entry) => {
          if (!group_entries[entry[this.groupBy]]) {
            group_entries[entry[this.groupBy]] = []
          }
          group_entries[entry[this.groupBy]].push(entry)
          return group_entries
        },
        []
      )

      return Object.keys(filteredByGroup)
        .sort()
        .reduce((obj, key) => {
          obj[key] = filteredByGroup[key]
          return obj
        }, {})
    }
  },
  watch: {
    displayUpdateCategory(newValue) {
      if (newValue !== false) {
        this.displayUpdateCategory = true
      }
    }
  },
  mounted() {
    if (this.filters.length) {
      this.$emit('update:filters', this.filters)
    }
  },
  methods: {
    handleClosePopover(close) {
      if (typeof close === 'function') {
        close()
      }
      this.$emit('update:filters', this.filters)

      this.updateStorage()
    },
    updateStorage() {
      if (this.storageName) {
        localStorage.setItem(this.storageName, JSON.stringify(this.filters))
      }
    },
    updateCategories() {
      this.$emit('updateCategories', this.categoryArray)
      this.displayUpdateCategory = false
    }
  }
}
</script>
