<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-col gap-2">
      <h4 class="text-base font-bold">Settings</h4>
      <div class="grid grid-cols-1 gap-2 md:grid-cols-6">
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.corporation"
            label="corporation"
            name="corporation"
            size="extra-small"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.listing"
            label="listing"
            name="listing"
            size="extra-small"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.material_resources"
            label="material_resources"
            name="material_resources"
            size="extra-small"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.user"
            label="utilisateur"
            name="user"
            size="extra-small"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.dossier_numbering"
            label="dossier_numbering"
            name="dossier_numbering"
            size="extra-small"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.invoicing_numbering"
            label="invoicing_numbering"
            name="invoicing_numbering"
            size="extra-small"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.bank_accounts"
            label="bank_accounts"
            name="bank_accounts"
            size="extra-small"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.tasks"
            label="tasks"
            name="tasks"
            size="extra-small"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.dashboard"
            label="dashboard"
            name="dashboard"
            size="extra-small"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.suppliers"
            label="suppliers"
            name="suppliers"
            size="extra-small"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.invoicing_categories"
            label="invoicing_categories"
            name="invoicing_categories"
            size="extra-small"
          />
        </div>
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.settings.item_categories"
            label="item_categories"
            name="item_categories"
            size="extra-small"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <h4 class="text-base font-bold">Defunt</h4>
      <div class="grid grid-cols-1 gap-2 md:grid-cols-6">
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.deceased.deceased"
            label="défunt"
            name="corporation"
            size="extra-small"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <h4 class="text-base font-bold">Facturation</h4>
      <div class="grid grid-cols-1 gap-2 md:grid-cols-6">
        <div>
          <BaseBooleanRadioGroup
            v-model="localSettings.invoicing.invoice"
            label="invoice"
            name="invoice"
            size="extra-small"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseBooleanRadioGroup from '@c/addf-package/components/BaseRadioGroup/BaseBooleanRadioGroup.vue'

export default {
  name: 'FuneralManagerV2ImportTaskSettings',
  components: { BaseBooleanRadioGroup },
  props: {
    settings: {
      type: Object,
      required: true
    }
  },
  emits: ['update:settings'],
  computed: {
    localSettings: {
      get() {
        console.log(this.settings)
        return this.settings
      },
      set(value) {
        this.$emit('update:settings', value)
      }
    }
  }
}
</script>
