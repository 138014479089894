<template>
  <div class="flex flex-col gap-4 rounded border p-4">
    <div>
      <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
        <SelectComponent
          v-model="importElement.type"
          :values="import_types"
          name="type"
          required
          :label="$t('attributes.type')"
          :edition-mode="!importElement.id"
        />
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <h3 class="text-base font-semibold">Options</h3>
      <FuneralManagerV2ImportSettings
        v-if="importElement.type === 'funeral_manager_v2'"
        v-model:settings="importElement.settings"
        :errors="errors?.settings"
      />
    </div>
    <div class="flex justify-end">
      <BaseButton :title="$t('button.save')" @click="saveImport" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SelectComponent from '@c/BaseFormComponent/SelectComponent.vue'
import FuneralManagerV2ImportSettings from '@/views/sa_management/import/component/specific/FuneralManagerV2ImportSettings.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'

export default {
  name: 'ImportEditComponent',
  components: { BaseButton, FuneralManagerV2ImportSettings, SelectComponent },
  props: {
    importId: {
      type: String,
      required: true
    }
  },
  emits: [],
  data() {
    return {
      importElement: {
        type: null,
        settings: []
      }
    }
  },
  computed: {
    ...mapGetters({
      import_types: 'metadata/getImportTypes',
      errors: 'auth/getErrors'
    })
  },
  watch: {
    'importElement.type'(newValue, oldValue) {
      if (!this.importElement.id) {
        if (newValue === 'funeral_manager_v2') {
          this.importElement.settings = {
            url: '',
            token: ''
          }
        }
      }
    }
  },
  mounted() {
    if (this.importId) {
      this.processGetImportAction({
        id: this.importId
      }).then((response) => {
        this.importElement = response.import
      })
    } else {
      this.processFetchNewImportAction().then((response) => {
        this.importElement = response.Import
      })
    }
  },
  methods: {
    ...mapActions({
      processFetchNewImportAction: 'saManagement/fetchNewImport',
      processGetImportAction: 'saManagement/getImport',
      processCreateImportAction: 'saManagement/createImport',
      processUpdateImportAction: 'saManagement/updateImport'
    }),
    saveImport() {
      if (this.importElement.id) {
        this.processUpdateImportAction(this.importElement).then((response) => {
          this.importElement = response.import
        })
      } else {
        this.processCreateImportAction(this.importElement).then((response) => {
          this.importElement = response.import
          this.$router.push({
            name: 'import_edit_import_route',
            params: {
              importId: this.importElement.id
            }
          })
        })
      }
    }
  }
}
</script>
