<template>
  <div class="w-full">
    <div
      class="relative overflow-hidden rounded-md border border-slate-300 bg-white shadow-sm focus-within:border-primary-400 focus-within:ring-0 dark:border-slate-300 dark:bg-slate-700 dark:text-gray-300"
      :class="cGroupClass"
    >
      <input
        v-model="internalValue"
        type="text"
        class="block w-full flex-1 border-0 text-gray-900 placeholder-gray-500 focus:ring-0 dark:border-slate-300 dark:bg-slate-700 dark:text-gray-300 sm:text-sm"
        :class="cInputClass"
        @click.stop
        @focusin.stop
      />
      <div
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
      >
        <FontAwesomeIcon :icon="['fal', 'search']" />
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'BaseSearchInput',
  components: { FontAwesomeIcon },
  props: {
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    modelValue: {
      type: String,
      required: false,
      default: null
    },
    size: {
      type: String,
      required: false,
      default: 'md'
    }
  },
  data() {
    return {
      searchTerms: ''
    }
  },
  computed: {
    cGroupClass() {
      return this.groupClass
    },
    cInputClass() {
      switch (this.size) {
        case 'extra-small':
          return 'px-2 py-1 gap-1 text-xs'
        case 'small':
        case 'sm':
          return 'px-3 py-1.5'
        case 'large':
          return 'px-5 py-3'
        case 'medium':
        default:
          return 'px-4 py-2'
      }
    },
    internalValue: {
      get() {
        if (this.modelValue) {
          return this.modelValue
        }
        return this.searchTerms
      },
      set(newValue) {
        if (this.modelValue !== null) {
          this.$emit('update:modelValue', newValue)
          this.$emit('type-input', newValue)
        } else {
          this.searchTerms = newValue
          this.$emit('type-input', newValue)
        }
      }
    }
  }
  // watch: {
  //   searchTerms(newValue) {
  //     this.$emit('type-input', newValue)
  //   }
  // },
}
</script>
