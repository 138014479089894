<template>
  <RouterLink
    :to="{ name: 'catalog_item_page_route', params: { itemId: item.id } }"
    class="group flex flex-col rounded-lg border shadow-md"
  >
    <div class="overflow-hidden p-2">
      <img
        v-if="item.master_image_url !== null && imgAvailable"
        :src="item.master_image_url"
        class="aspect-video w-full rounded-lg object-cover shadow"
        :alt="item.name"
        @error="() => (imgAvailable = false)"
      />
      <div
        v-else
        class="flex aspect-video w-full items-center justify-center rounded-lg bg-gray-200"
      >
        <FontAwesomeIcon
          :icon="['fas', 'image']"
          class="text-8xl text-gray-300"
        />
      </div>
    </div>
    <div class="flex flex-row px-4 pb-2">
      <div class="flex-1">
        <h3 class="text-base font-bold text-gray-500">
          {{ item?.name[$i18n.locale] }}
        </h3>
      </div>
      <div class="flex shrink-0 flex-col items-end text-sm">
        <template v-if="metadata.settings.price_encoding_type === 'TVAC'">
          <div class="flex gap-2">
            <span class="text-gray-400">{{
              $t('invoicing.including_vat_abbreviated')
            }}</span>
            <span>
              {{ $h.formatCurrency(item[`default_price_tvac`]) }}
            </span>
          </div>
          <div class="flex gap-2">
            <span class="text-gray-400">{{
              $t('invoicing.excluding_vat_abbreviated')
            }}</span>
            <span>{{ $h.formatCurrency(item[`default_price_htva`]) }}</span>
          </div>
        </template>
        <template v-else>
          <div class="flex gap-2">
            <span class="text-gray-400">{{
              $t('invoicing.excluding_vat_abbreviated')
            }}</span>
            <span>
              {{ $h.formatCurrency(item[`default_price_htva`]) }}
            </span>
          </div>
          <div class="flex gap-2">
            <span class="text-gray-400">{{
              $t('invoicing.including_vat_abbreviated')
            }}</span>
            <span>{{ $h.formatCurrency(item[`default_price_tvac`]) }}</span>
          </div>
        </template>
      </div>
    </div>
  </RouterLink>
</template>
<script>
import { mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'ItemsCategoryComponent',
  components: { FontAwesomeIcon },
  props: {
    item: {
      type: Object,
      required: true
    },
    funeralHome: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['item-selected'],
  data() {
    return {
      imgAvailable: true
    }
  },
  computed: {
    ...mapGetters({
      metadata: 'metadata/getInitialMetadata'
    })
  }
}
</script>
