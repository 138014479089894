<template>
  <div
    class="box relative mx-5 flex h-full flex-col overflow-y-scroll bg-gray-50"
  >
    <div class="sticky left-0 right-0 top-0 z-10 flex flex-col">
      <div
        class="flex shrink-0 justify-between border-b bg-white bg-opacity-90 p-3"
      >
        <div class="flex justify-center gap-x-3">
          <div class="flex items-center gap-x-3">
            <FontAwesomeIcon v-if="icon" :icon="['fal', icon]" size="xl" />
            <h2 class="text-2xl font-bold">{{ title }}</h2>
          </div>
        </div>
        <div class="flex justify-center">
          <div class="flex gap-x-3" :class="ctaClass">
            <slot name="cta" />
          </div>
        </div>
      </div>
      <div v-if="tabs" class="shrink-0">
        <BaseSimpleTab :tabs="tabs" color-when-selected="primary" />
      </div>
    </div>
    <div class="flex flex-1 flex-col @container/dossier-main">
      <RouterView v-if="tabs" />
      <slot v-else name="default" />
    </div>
    <div
      v-if="$slots.footer || $route.meta.pre_page || $route.meta.next_page"
      class="sticky bottom-0 left-0 right-0 z-10 flex justify-end gap-x-3 border-t bg-white bg-opacity-90 p-3"
    >
      <BaseButton
        v-if="$route.meta.pre_page"
        color="info"
        :title="$t('button.previous')"
        @click="handleMoveButton($route.meta.pre_page)"
      />
      <BaseButton
        v-if="$route.meta.next_page"
        color="info"
        :title="$t('button.next')"
        @click="handleMoveButton($route.meta.next_page)"
      />
      <slot name="footer" />
      <!--#endregion -->
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import BaseSimpleTab from '@c/addf-package/components/BaseTabs/BaseSimpleTab.vue'

export default {
  name: 'BaseWikiCityPageLayout',
  components: { BaseSimpleTab, BaseButton, FontAwesomeIcon },
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    ctaClass: {
      type: String,
      required: false,
      default: ''
    },
    tabs: {
      type: Array,
      required: false,
      default: null
    }
  },
  methods: {
    handleMoveButton(route_name) {
      this.$router.push({
        name: route_name,
        params: { id: this.$route.params.id }
      })
    }
  }
}
</script>
