<template>
  <!--region company-->
  <ContactCardCompanyComponent
    v-if="contact.is_company"
    :edition-mode="editionMode"
    :contact="contact"
    :errors="errors"
  />
  <!--  <hr v-if="contactType === ContactType.GENERAL_CONTACT" />-->
  <!--endregion-->

  <!--region minimal informations-->
  <div
    v-if="!contact.is_company"
    class="grid grid-cols-1 gap-5 md:grid-cols-2"
    :class="contact.is_company ? 'py-5' : 'pb-5'"
  >
    <div class="col-span-2">
      <SelectCivilityAndGender
        v-model:civility="contact.civility"
        v-model:gender="contact.gender"
        :edition-mode="editionMode"
        :errors-civility="errors?.civility"
        :errors-gender="errors?.gender"
      />
    </div>
    <div>
      <SelectRelationship
        v-model="contact.relationship"
        :edition-mode="editionMode"
        :section="section"
      />
    </div>
    <div>
      <BaseShowEditInputNameFormat
        v-model="contact.last_name"
        v-model:brut="contact.brut_last_name"
        :label="$t('attributes.name')"
        name="name"
        :edition-mode="editionMode"
        :required="true"
        :errors="errors.last_name"
      />
    </div>
    <div>
      <BaseShowEditInput
        v-model="contact.first_name"
        :label="$t('attributes.first_name')"
        name="first_name"
        :edition-mode="editionMode"
        :required="true"
        :errors="errors.first_name"
      />
    </div>
    <div v-if="contact.gender === Gender.FEMALE">
      <BaseShowEditInput
        v-model="contact.maiden_name"
        :label="$t('attributes.maiden_name')"
        name="first_name"
        :edition-mode="editionMode"
      />
    </div>
    <div>
      <BaseShowEditInput
        v-model="contact.usual_name"
        :label="$t('attributes.usual_first_name')"
        :placeholder="$t('attributes.usual_first_name')"
        name="usual_first_name"
        :edition-mode="editionMode"
      />
    </div>
  </div>
  <div
    v-if="
      !contact.is_company &&
      (contact.id ||
        [
          ContactSection.ENFANT,
          ContactSection.ETAT_CIVIL,
          ContactSection.PARENT
        ].includes(section))
    "
  >
    <ContactCardDeathComponent
      :contact="contact"
      :edition-mode="editionMode"
      :contact-type="deceasedContactType"
      :with-undefined="false"
    />
  </div>
  <!-- endregion -->

  <!--region basic informations-->
  <div
    v-if="!contact.is_company && contact.person_type !== ContactType.MINIMAL"
    class="grid grid-cols-1 gap-5 py-5 md:grid-cols-2"
  >
    <div>
      <BaseShowEditDatePickerv2
        v-model:date="contact.birth_date"
        :label="$t('attributes.birth_date')"
        :today-default="false"
        :edition-mode="editionMode"
        name="birth_date"
      />
    </div>
    <div>
      <SelectCity
        v-model:city-id="contact.birth_place_id"
        v-model:city-object="contact.birth_place_object"
        v-model:city="contact.birth_place"
        :edition-mode="editionMode"
        :label="$t('attributes.birth_place')"
        allow-tags
      />
    </div>
    <div>
      <BaseShowEditInput
        v-model="contact.nationality"
        :label="$t('attributes.nationality')"
        :errors="errors"
        :edition-mode="editionMode"
      />
    </div>
    <div v-if="metadata.settings.default_country === Country.BELGIUM">
      <BaseShowEditNationalNumberInput
        v-model="contact.national_number"
        :country="Country.BELGIUM"
        :edition-mode="editionMode"
        :errors="errors?.contact?.national_number"
      />
    </div>
  </div>
  <!-- endregion -->

  <!--region family informations-->
  <div
    v-if="
      !contact.is_company &&
      contact.person_type !== ContactType.BASIC &&
      contact.person_type !== ContactType.MINIMAL
    "
    class="grid grid-cols-1 gap-x-6 gap-y-5 py-5 md:grid-cols-2"
  >
    <div class="col-start-1">
      <SelectOccupations
        v-model="contact.profession"
        :edition-mode="editionMode"
        :errors="errors?.profession"
      />
    </div>
    <div>
      <BaseShowEditInput
        v-model="contact.profession_other"
        :label="$t('attributes.profession_other')"
        name="profession_other"
        :edition-mode="editionMode"
      />
    </div>
    <div>
      <BaseShowEditInput
        v-model="contact.profession_practised"
        :label="$t('attributes.profession_practised')"
        name="profession_practised"
        :edition-mode="editionMode"
      />
    </div>
  </div>
  <!-- endregion -->
</template>

<script>
import { mapGetters } from 'vuex'
import { ContactType, Gender, Country, ContactSection } from '@/assets/enums'

import ContactCardCompanyComponent from '@/views/dossier/form/subpages/contact/components/components/ContactCardCompanyComponent'
import SelectRelationship from '@c/InitialStateFormComponent/Enums/SelectRelationship.vue'
import SelectOccupations from '@c/InitialStateFormComponent/Enums/SelectOccupations.vue'
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'
import BaseShowEditDatePickerv2 from '@c/addf-package/components/BaseShowEditDatePicker/BaseShowEditDatePickerv2.vue'
import BaseShowEditNationalNumberInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditNationalNumberInput.vue'
import SelectCity from '@c/SelectAjaxComponent/SelectCity.vue'
import ContactCardDeathComponent from '@/views/dossier/form/subpages/contact/components/components/ContactCardDeathComponent.vue'
import BaseShowEditInputNameFormat from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInputNameFormat.vue'
import SelectCivilityAndGender from '@c/InitialStateFormComponent/Enums/SelectCivilityAndGender.vue'

export default {
  name: 'ContactCardInformationComponent',
  components: {
    SelectCivilityAndGender,
    ContactCardDeathComponent,
    BaseShowEditInputNameFormat,
    SelectCity,
    BaseShowEditNationalNumberInput,
    BaseShowEditDatePickerv2,
    BaseShowEditInput,
    SelectOccupations,
    SelectRelationship,
    ContactCardCompanyComponent
  },
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    contact: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({})
    },
    contactType: {
      type: String,
      required: true
    },
    deceasedContactType: {
      type: String,
      required: true,
      default: 'general_contact'
    },
    canTrackByBeNull: {
      type: Boolean,
      required: false,
      default: true
    },
    section: {
      type: String,
      required: false,
      default: ContactSection.GENERAL,
      validator: (value) =>
        [
          ContactSection.ETAT_CIVIL,
          ContactSection.PARENT,
          ContactSection.ENFANT,
          ContactSection.GENERAL
        ].indexOf(value) !== -1
    }
  },
  data() {
    return {
      ContactType: ContactType,
      Country: Country,
      Gender: Gender
    }
  },
  computed: {
    ...mapGetters({
      metadata: 'metadata/getInitialMetadata'
    }),
    ContactSection() {
      return ContactSection
    },
    birthCityBaseValue() {
      return {
        id: this.contact.birth_place_id,
        full_name: this.contact.birth_place
      }
    },
    maritalStatus() {
      return this.contact?.marital_status
    }
  },
  methods: {
    handleBirthCitySelected(payload) {
      if (payload) {
        this.contact.birth_place_id = payload.id
        this.contact.birth_place = payload.full_name
      } else {
        this.contact.birth_place_id = null
        this.contact.birth_place = null
      }
    }
  }
}
</script>
