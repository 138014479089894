<template>
  <div class="flex flex-col gap-x-2">
    <div class="my-2 flex items-center justify-between gap-x-3">
      <BaseButton
        v-if="localContactId"
        :title="$t('button.reset')"
        class="mt-0.5"
        icon="trash"
        color="warning"
        @click="localContactId = null"
      />
      <BaseSimpleSearchInput v-model:modelValue="filterText" />
    </div>
    <BaseButton
      title="tous les contacts"
      :icon="!funeralHomeFilter ? 'eye' : 'eye-slash'"
      class="mt-0.5"
      @click="toggleAllContacts"
    />
    <template v-if="filteredContact.length < 1">
      <div class="mx-auto mt-12 text-center text-slate-300">
        {{
          $t('global.there_is_no_yet', {
            settings: roleId ? roleName?.toLowerCase() : $t('global.contact')
          })
        }}
        <span v-if="funeralHomeId">
          <span>{{ $t('global.for_funeral_home') + ' ' }}</span>
          <span class="font-bold">{{
            metadata?.models?.funeralHomes?.filter(
              (fh) => fh.id === funeralHomeId
            )[0]?.name
          }}</span>
        </span>
      </div>
    </template>
    <template v-else>
      <div class="grid grid-cols-1 gap-4 py-4 md:grid-cols-2">
        <template v-for="contact in filteredContact" :key="contact.id">
          <SelectContactCard
            :contact="contact"
            :icon="icon"
            :selected="contact.id === localContactId"
            @edit-contact="handleEditContact"
            @click="selectContact(contact.id)"
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SelectContactCard from '@ui/modal/contact/components/SelectContactCard.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import BaseSimpleSearchInput from '@c/addf-package/components/BaseInput/BaseSimpleSearchInput.vue'

export default {
  name: 'SelectContactList',
  components: { BaseSimpleSearchInput, BaseButton, SelectContactCard },
  props: {
    contactId: {
      type: [String, Number],
      required: true
    },
    roleId: {
      type: [String, Number],
      required: false,
      default: null
    },
    funeralHomeId: {
      type: [String, Number],
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: false,
      default: 'user'
    },
    roleName: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: [
    'update:contactId',
    'selectContactContact',
    'editContact',
    'edit-contact'
  ],
  data() {
    return {
      filterText: '',
      contacts: []
    }
  },
  computed: {
    ...mapGetters({
      roleContactList: 'contact/getRoleContactList',
      metadata: 'metadata/getInitialMetadata',
      dossier: 'dossier/getDossier'
    }),
    localContactId: {
      get() {
        return this.contactId
      },
      set(value) {
        this.$emit('update:contactId', value)
      }
    },
    filteredContact() {
      let searchTerm = this.filterText.toLowerCase().trim()
      return this.contacts.filter((contact) => {
        return (
          contact?.company_name?.toLowerCase()?.includes(searchTerm) ||
          contact?.last_name?.toLowerCase()?.includes(searchTerm) ||
          contact?.first_name?.toLowerCase()?.includes(searchTerm)
        )
      })
    },
    funeralHomeFilter() {
      return !!this.funeralHomeId
    }
  },
  mounted() {
    this.processFetchContactListAction({
      roleId: this.roleId
    }).then((response) => (this.contacts = response.contacts))
  },
  methods: {
    ...mapActions({
      processFetchContactListAction: 'contact/fetchContactList'
    }),
    handleEditContact(contact_id) {
      this.$emit('edit-contact', contact_id)
    },
    selectContact(contact_id) {
      this.localContactId = contact_id
    },
    toggleAllContacts() {
      console.log('toggleAllContacts')
    }
  }
}
</script>
