<template>
  <div
    class="flex flex-row gap-4 rounded border px-4 py-2 shadow"
    :class="[
      description.length ? '' : 'items-center',
      backgroundColored
        ? 'border-primary-300 bg-primary-100'
        : 'border-primary-200'
    ]"
    :data-tint="type"
  >
    <div class="shrink-0 text-xl text-primary">
      <FontAwesomeIcon :icon="icon" />
    </div>
    <div class="flex-1">
      <h2 v-if="title?.length" class="font-semibold">{{ title }}</h2>
      <p v-if="description.length">{{ description }}</p>
      <p v-if="$slots.default"><slot name="default" /></p>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'InfoPanel',
  components: { FontAwesomeIcon },
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: 'info',
      validator: function (value) {
        const possibleTypes = ['info', 'danger', 'warning', 'success']
        return possibleTypes.indexOf(value) !== -1
      }
    },
    backgroundColored: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    icon() {
      switch (this.type) {
        case 'success':
          return ['fal', 'circle-check']
        case 'warning':
          return ['fal', 'exclamation-circle']
        case 'danger':
          return ['fal', 'exclamation-triangle']
        case 'info':
          return ['fal', 'circle-info']
      }
    }
  }
}
</script>
