<template>
  <div class="py-2 pr-3">
    <ContactForm
      v-model:contact="contact"
      :errors="errors"
      :role-is-disabled="!!roleId"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ContactForm from '@ui/modal/contact/components/ContactForm'

export default {
  name: 'SelectContactForm',
  components: { ContactForm },
  props: {
    contactId: {
      type: String,
      required: false,
      default: null
    },
    roleId: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['contact-added', 'contact-updated'],
  data() {
    return {
      contact: null
    }
  },
  computed: {
    ...mapGetters({
      errors: 'auth/getErrors'
    })
  },
  mounted() {
    if (this.contactId) {
      this.processFetchContactByIdAction({
        contact_id: this.contactId
      }).then((response) => {
        this.contact = response.contact
      })
    } else {
      this.processFetchNewContactAction().then((response) => {
        this.contact = {
          ...response.contact,
          roleId: this.roleId
        }
      })
    }
  },
  methods: {
    ...mapActions({
      processFetchContactByIdAction: 'contact/fetchContactById',
      processFetchNewContactAction: 'contact/fetchNewContact',
      processUpdateContactAction: 'contact/updateContact',
      processStoreNewContactAction: 'contact/storeNewContact'
    }),
    save() {
      if (this.contact.id) {
        this.processUpdateContactAction({
          id: this.contact.id,
          contact: this.contact
        }).then((response) => {
          this.$h.toastSuccess(this.$t('toasts.updated'))
          this.$emit('contact-updated', response.contact)
        })
      } else {
        this.processStoreNewContactAction({
          contact: this.contact
        }).then((response) => {
          this.$h.toastSuccess(this.$t('toasts.added'))
          this.$emit('contact-updated', response.contact)
        })
      }
    }
  }
}
</script>
