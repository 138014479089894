const ScreenRootLayout = () => import('@/views/screen/ScreenRootLayout.vue')
const ScreenLogin = () => import('@/views/screen/ScreenLogin.vue')
const Screen = () => import('@/views/screen/ScreenShow.vue')

export const screenRootPages = [
  {
    path: '/screen',
    component: ScreenRootLayout,
    name: 'screen-root-layout',
    children: [
      {
        path: '',
        name: 'screen',
        component: Screen
      },
      {
        path: 'login',
        name: 'screen-login',
        component: ScreenLogin
      }
    ]
  }
]
