<template>
  <RouterLink
    v-if="!disabled"
    :to="to"
    :active-class="'bg-primary-500 hover:bg-primary-500 text-white hover:text-white dark:text-blue-900 font-medium'"
    :class="[
      `group flex flex-col rounded-md px-2.5 py-1.5 hover:bg-primary-400 hover:text-white dark:text-white`
    ]"
  >
    <div class="flex flex-1 flex-row">
      <div class="flex-shrink-0">
        <FontAwesomeIcon
          :icon="Array.isArray(icon) ? icon : ['fal', icon]"
          fixed-width
        />
      </div>
      <div
        class="flex flex-1 flex-row items-center gap-2"
        :class="[
          floatingMenu
            ? 'w-0 overflow-hidden opacity-0 transition-all group-hover/menu:ml-3 group-hover/menu:w-72 group-hover/menu:opacity-100'
            : 'ml-3 w-72'
        ]"
      >
        <span class="flex-1 cursor-pointer truncate capitalize-first">
          {{ label }}
        </span>
        <span
          v-if="subItems && subItems.length"
          class="shrink-0"
          @click="isOpen = !isOpen"
        >
          <FontAwesomeIcon
            v-if="!disabled && isActive"
            class="float-right transition"
            :class="[
              isOpen
                ? 'inline-flex rotate-180'
                : 'hidden group-hover:inline-flex'
            ]"
            :icon="['fal', 'chevron-down']"
            fixed-width
          />
        </span>
      </div>
    </div>
    <div
      v-if="!disabled && isActive && isOpen && subItems?.length"
      class="flex flex-col"
    >
      <div class="p-2 pl-5">
        <ul class="ml-5 list-disc space-y-1 text-xs font-medium">
          <li
            v-for="(item, idx) in subItems"
            :key="idx"
            v-scroll-to="{
              element: item.anchor,
              container: '#dossier_layout',
              offset: -65
            }"
            class="capitalize-first hover:font-bold hover:text-gray-900"
          >
            <!--            <router-link :to="{hash: `#${item.anchor}`}">-->
            <p>{{ item.label }}</p>
            <p class="ml-1 font-light">{{ item.subLabel }}</p>
            <!--            </router-link>-->
          </li>
        </ul>
      </div>
    </div>
  </RouterLink>

  <div
    v-else
    :class="activeClasses"
    class="flex items-center gap-x-1 p-1.5 text-gray-500 dark:text-white"
  >
    <font-awesome-icon
      :icon="Array.isArray(icon) ? icon : ['fal', icon]"
      size="lg"
      fixed-width
    />&nbsp;
    <p class="cursor-pointer capitalize-first">{{ label }}</p>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'DossierNavLink',
  components: { FontAwesomeIcon },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    to: {
      type: Object,
      required: true
    },
    icon: {
      type: [Array, String],
      required: true
    },
    subItems: {
      type: Object,
      required: false,
      default: () => {}
    },
    label: {
      type: String,
      required: true
    },
    routeName: {
      type: String,
      required: true
    },
    subRoutes: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    floatingMenu: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  computed: {
    acceptedRoutes() {
      return [this.routeName, ...this.subRoutes]
    },
    isActive() {
      if (this?.subItems?.length > 0) {
        this.isOpen = true
      }
      return this.acceptedRoutes.includes(this.$route.name)
    }
  }
}
</script>
