import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import store from '../../index'

function isSuperAdmin(that) {
  // let role = store.getters['auth/getAuthRole']
  console.log('role', that)
  return 'see_content'
}

export default {
  namespaced: true,
  state() {
    return {
      collapsed: localStorage.getItem('collapsed') || false,
      menu: [
        {
          icon: 'gauge-max',
          pageName: 'dashboard_main_page_route',
          title: 'template_dashboard_title_main'
        },
        {
          icon: 'folder',
          pageName: 'dashboard_dossier_main_page_route',
          title: 'dossier_title',
          meta: {
            breadcrumb: [
              { title: 'Content', active: false },
              { title: 'Company', active: true }
            ]
          }
        },
        /* {
                     icon: 'calendar',
                     pageName: 'calendar_page_route',
                     title: 'calendar_title',
                     subMenu: [
                         {
                             icon: 'calendar-days',
                             pageName: 'agenda_page_route',
                             title: 'agenda_title'
                         },

                         {
                             icon: 'calendar-clock',
                             pageName: 'prestations_page_route',
                             title: 'prestations_title'
                         },
                         {
                             icon: 'calendar-range',
                             pageName: 'disponibility_page_route',
                             title: 'disponibility_title'
                         },
                         {
                             icon: 'calendar-day',
                             pageName: 'personal_planning_page_route',
                             title: 'personal_planning_title'
                         },
                         {
                             icon: 'calendar-week',
                             pageName: 'funerarium_planning_page_route',
                             title: 'funerarium_planning_title'
                         }
                     ]
                 },*/
        {
          icon: 'calendar-days',
          pageName: 'agenda_page_route',
          title: 'agenda_title'
        },
        {
          icon: 'calendar-circle-user',
          pageName: 'planning_page_route',
          title: 'planning_title'
        },
        {
          icon: 'mailbox',
          pageName: 'mail_box_page',
          title: 'messaging_title'
        },
        //{
        //  icon: 'calendar-clock',
        //  pageName: 'prestations_page_route',
        //  title: 'prestations_title'
        //},
        {
          icon: 'address-book',
          pageName: 'address_book_page_route',
          title: 'address_book_title_main'
        },
        {
          icon: 'location-dot',
          pageName: 'wiki_city_page_route',
          title: 'wiki_commune_title_main'
        },
        // {
        //   icon: 'books',
        //   pageName: 'wiki_route',
        //   title: 'wiki_title',
        //   subMenu: [
        //     {
        //       icon: 'location-dot',
        //       pageName: 'wiki_city_page_route',
        //       title: 'wiki_commune_title_main'
        //     }
        //region hidden carte d'itinéraire
        // {
        //     icon: 'map-location-dot',
        //     pageName: 'wiki_locations_page_route',
        //     title: 'wiki_locations_title_main'
        // }
        //endregion
        //   ]
        // },
        {
          icon: 'square-check',
          pageName: 'tasks_index_route',
          title: 'tasks_title_main'
        },
        // {
        //   icon: 'comment',
        //   pageName: 'condolences_page_route',
        //   title: 'condolences_title_main'
        // },
        // {
        //   icon: 'messages',
        //   pageName: 'chat_page_default_route',
        //   title: 'chat_title_main'
        // },
        // {
        //   icon: 'handshake',
        //   pageName: 'partners_page_route',
        //   title: 'partners_title_main',
        //   subMenu: [
        //     {
        //       icon: 'handshake',
        //       pageName: 'enaos_page_route',
        //       title: 'enaos_title_main'
        //     },
        //     {
        //       icon: 'handshake',
        //       pageName: 'inMemory_page_route',
        //       title: 'inMemory_title_main'
        //     },
        //     {
        //       icon: 'handshake',
        //       pageName: 'fragment_page_route',
        //       title: 'fragment_title_main'
        //     },
        //     {
        //       icon: 'handshake',
        //       pageName: 'funebra_page_route',
        //       title: 'funebra_title_main'
        //     }
        //   ]
        // },
        // {
        //   icon: 'book-open',
        //   pageName: 'note_index_route',
        //   title: 'note',
        //   gate: 'see_content'
        // },
        {
          icon: 'euro-sign',
          pageName: 'invoicing_root_page',
          title: 'invoicing_title_main',
          gate: 'see_invoicing',
          meta: { open_for_customer: true }
        },
        {
          icon: 'file-signature',
          pageName: 'electronic_signature_index_route',
          title: 'electronic_signature_title_main',
          gate: 'super-admin|agent',
          functionality: 'electronic_signature'
        },
        // {
        //   icon: 'truck-clock',
        //   pageName: 'supplier_order_page_route',
        //   title: 'supplier_order_title_main'
        // },
        {
          icon: 'analytics',
          pageName: 'dashboard_statistics_route',
          title: 'statistics_title'
        },
        {
          icon: 'boxes-stacked',
          pageName: 'stock_page_route',
          title: 'stock_title'
        },
        {
          icon: 'store',
          pageName: 'marketplace_list',
          title: 'marketplace_title_main',
          gate: 'see_marketplace'
        },
        {
          icon: 'cogs',
          pageName: 'settings_list_user_route',
          title: 'settings'
        },
        {
          icon: 'gauge-circle-bolt',
          pageName: 'management_general_route',
          title: 'sa_management',
          gate: 'super-admin'
        }
      ]
    }
  },
  mutations,
  actions,
  getters
}
