<template>
  <div v-if="editionMode" :class="groupClass">
    <div>
      <p v-if="formatedTransfertsList.length === 0"
         class="text-lg font-semibold leading-6 text-gray-900 capitalize-first">
        {{ $t('attributes.no_transferts_in_dossier') }}
      </p>
      <SelectComponent
        v-else
        v-model="internalValue"
        :values="formatedTransfertsList"
        :label="label ? label : $t('attributes.transferts')"
        name="demandeur"
        :required="required"
      />

    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import BaseEditLabel from '@c/addf-package/components/BaseLabel/BaseEditLabel.vue'
import SelectComponent from '@c/BaseFormComponent/SelectComponent.vue'

export default {
  name: 'BaseTransfertDossierShowEditSelectSimple',
  components: {
    BaseEditLabel,
    SelectComponent
  },
  props: {
    name: {
      type: String,
      required: false,
      default: 'contact'
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    modelValue: {
      type: String,
      required: true
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name'
    },
    dontDisplayLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false
    },
    groupClass: {
      type: String,
      required: false,
      default: ''
    },
    editionMode: {
      type: Boolean,
      default: true
    },
    fullModelResponse: {type: Boolean, required: false, default: false}
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      transferts: []
    }
  },
  computed: {
    formatedTransfertsList() {
      console.log(this.transferts.transferts)
      if (
        Array.isArray(this.transferts.transferts) &&
        this.transferts.transferts.length
      ) {
        return this.transferts.transferts.map((transfert) => {
          return {
            id: transfert.id,
            name: transfert.full_name
          }
        })
      }
      return []
    },
    internalValue: {
      get() {
        if (!this.modelValue) {
          return null
        }
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
        this.$emit('change', newValue)
      }
    }
  },
  mounted() {
    this.processFetchTransfertsDossierAction({
      dossier_id: this.$route.params.id
    }).then((transferts) => (this.transferts = transferts))
  },
  methods: {
    ...mapActions({
      processFetchTransfertsDossierAction: 'dossier/fetchTransfertsDossierList'
    })
  }
}
</script>
