const TheAccountingDiaryIndexPage = () =>
  import(
    '@/views/settings/invoicing/accountingDiary/TheAccountingDiaryIndexPage.vue'
  )

export const settingsAccountingDiaryRoutes = [
  {
    path: 'accounting-diary',
    name: 'settings_accounting_diaries_route',
    component: TheAccountingDiaryIndexPage,
    meta: {
      breadcrumb: [
        { title: 'content_title', active: false },
        { title: 'content_company_title', active: true }
      ]
    }
  }
]
