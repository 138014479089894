// const PlanningPage = () => import( /* webpackChunkName: "group-calendar" */ "@/views/calendar/PlanningPage"; )

import store from '@/store'
import DashboardTypes from '@/assets/enums/dashboard/DashboardTypes'

const WorkInProgressComponent = () =>
  import(
    /* webpackChunkName: "group-calendar" */ '@/views/wip/WorkInProgressComponent'
  )
const ThePlanningPage = () =>
  import(
    /* webpackChunkName: "group-calendar" */ '@/views/calendar/planning/ThePlanningPage'
  )
const TheAgendaPage = () =>
  import(
    /* webpackChunkName: "group-calendar" */ '@/views/calendar/agenda/TheAgendaPage'
  )

export const calendarPages = [
  {
    path: 'calendar',
    children: [
      {
        path: 'agenda',
        children: [
          {
            path: '',
            name: 'agenda_page_route',
            component: TheAgendaPage,
            meta: {
              route_name: 'agenda_page_route_id'
            },
            children: [
              {
                path: '',
                beforeEnter: (from, to, next) => {
                  console.log('beforeEnter')
                  let template = store.getters[
                    'metadata/getTemplateDashboardByType'
                  ](DashboardTypes.AGENDA)
                  if (Array.isArray(template) && template[0]) {
                    next({
                      name: 'agenda_page_route_id',
                      params: { id: template[0].id }
                    })
                    return
                  }
                  next()
                  return
                }
              },
              {
                path: ':id',
                name: 'agenda_page_route_id',
                component: TheAgendaPage
              }
            ]
          }
        ]
      },
      {
        path: 'planning',
        children: [
          {
            path: '',
            name: 'planning_page_route',
            component: ThePlanningPage,
            meta: {
              route_name: 'planning_page_route_id'
            },
            children: [
              {
                path: '',
                beforeEnter: (from, to, next) => {
                  console.log('beforeEnter')
                  let template = store.getters[
                    'metadata/getTemplateDashboardByType'
                  ](DashboardTypes.PLANNING)
                  if (Array.isArray(template) && template[0]) {
                    next({
                      name: 'planning_page_route_id',
                      params: { id: template[0].id }
                    })
                    return
                  }
                  next()
                  return
                }
              },
              {
                path: ':id',
                name: 'planning_page_route_id',
                component: ThePlanningPage
              }
            ]
          }
        ]
      },
      {
        path: 'prestations',
        name: 'prestations_page_route',
        component: WorkInProgressComponent
      },
      {
        path: 'disponibility',
        name: 'disponibility_page_route',
        component: WorkInProgressComponent
      },
      {
        path: 'personal_planning',
        name: 'personal_planning_page_route',
        component: WorkInProgressComponent
      },
      {
        path: 'funerarium_planning',
        name: 'funerarium_planning_page_route',
        component: WorkInProgressComponent
      }
    ]
  }
]
