import apiClient from '@u/apiClient'
const base_errors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

function errorSuccessCallback(payload) {
  if (payload.errorCallback) {
    payload.errorCallback()
  }
}
export default {
  // region fetch
  async fetchStatisticsForExport({ commit }, payload) {
    await apiClient
      .post(`/statistics/statistic-export/${payload.id}`)
      .then(async (response) => {
        window.open(response.data.url)
        processSuccessCallback(payload)
      })
      .catch((reason) => {
        console.error('errors', reason)
        commit('auth/setErrors', reason.response, { root: true })
      })
  },
  // endregion
}
