<template>
  <div class="flex flex-row">
    <div class="rounded-l bg-primary px-2 py-0.5 text-contrast-primary">
      {{ attributeText }}
    </div>
    <div  :class="[condition.arguments ? '' : 'rounded-r', 'border-y border-r border-slate-600 px-2 py-0.5']"
    >
      {{ text }}
    </div>
    <div v-if="condition.arguments" class="rounded-r border-y border-r border-slate-600 px-2 py-0.5 font-light">
      <span class="font-bold">{{ condition.labels?.join(', ') }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'AutomationConditionBadge',
  props: {
    condition: {
      type: Object,
      required: true
    },
    metadata: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      initialMetadata: 'metadata/getInitialMetadata'
    }),
    attributeText() {
      return this.$t(
        'automation.attribute.' +
          this.metadata.conditions_attributes.find(
            (item) => item.id === this.condition.attribute
          ).id
      )
    },
    text() {
      let operator_text =
        this.metadata.conditions_operators.find(
          (item) => item.id === this.condition.operator
        ).name ?? ''
      return `${operator_text}`
    }
  }
}
</script>
