<template>
  <div
    class="scrollbar-custom flex h-full flex-col overflow-auto rounded-2xl bg-white p-5"
  >
    <div class="flex flex-shrink-0 flex-row">
      <h2 class="text-2xl font-medium">Playground</h2>
    </div>
    <div class="grid min-h-0 grid-cols-4 gap-8">
      <div>
        <DateRangeNavigator v-model:start="start" v-model:end="end" />
      </div>
      <div>{{ start }} {{ end }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DateRangeNavigator from '@c/SelectComponent/DateRangeNavigator.vue'

export default {
  name: 'PlaygroundComponent',
  components: {
    DateRangeNavigator
  },
  data() {
    return {
      start: '2024-09-08',
      end: '2024-09-12'
    }
  },
  computed: {
    ...mapGetters({
      users: 'metadata/getUsersMetadata'
    })
  }
}
</script>
