<template>
  <div v-if="editionMode" class="flex flex-col gap-2">
    <label class="text-xs font-medium text-gray-900 capitalize-first">
      {{ $t('global.requester_type') }}
    </label>
    <div v-if="localModelValue">
      <div class="flex flex-col gap-1">
        <div
          v-for="(type, index) in valueTypes"
          :key="index"
          class="flex flex-row items-center gap-1"
        >
          <input
            :id="index"
            v-model="localModelValue.type"
            :value="type.id"
            type="radio"
            class="ring-0 checked:ring-0"
          />
          <label :for="index" class="capitalize-first">{{
            $t('attributes.' + type.name)
          }}</label>
        </div>
      </div>
    </div>
    <div v-if="localModelValue">
      <template v-if="localModelValue.type === 'FH'">
        <SelectFuneralHome
          v-model="localModelValue.id"
          error-key="funeral_home"
          :edition-mode="editionMode"
          required
        />
      </template>
      <template v-else>
          <SelectComponent
            v-model="localModelValue.id"
            :values="formatedContactList"
            :label="$t('global.contact')"
            name="contact"
            required
          />
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SelectFuneralHome from '@c/InitialStateFormComponent/SelectFuneralHome.vue'
import SelectComponent from '@c/BaseFormComponent/SelectComponent.vue'
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'

let default_value = {
  type: 'DD',
  id: null
}

export default {
  name: 'BaseSelectDemandeurFormSimple',
  components: { SelectComponent, SelectFuneralHome },
  props: {
    name: {
      type: String,
      required: false,
      default: 'contact'
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    modelValue: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    editionMode: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      contacts: [],
      valueTypes: [
        {
          id: 'DD',
          name: 'requester'
        },
        {
          id: 'FH',
          name: 'funeral_home'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      dossier: 'dossier/getDossier'
    }),
    formatedContactList() {
      if (
        Array.isArray(this.dossier.contacts) &&
        this.dossier.contacts.length
      ) {
        return this.dossier.contacts.map((contact) => {
          return {
            id: contact.id,
            name: contact.full_name
          }
        })
      }
      return []
    },
    localModelValue: {
      get() {
        if (!this.modelValue) {
          return null
        }
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  watch: {
    'modelValue.type'(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue === 'DD') {
          this.localModelValue = {
            ...this.modelValue,
            id: this.contacts.contacts?.find((contact) => contact.is_handler)?.id
          }
        } else if (newValue === 'FH') {
          this.localModelValue = {
            ...this.modelValue,
            id: this.dossier.funeral_home_id
          }
        }
      }
    }
  },
  mounted() {
    if (!this.modelValue || !this.modelValue?.type) {
      this.localModelValue = { ...default_value }
    }
    if (this.dossier.id) {
      this.processFetchContactDossierAction({
        dossier_id: this.dossier.id
      }).then((contacts) => (this.contacts = contacts))
    }
  },
  methods: {
    ...mapActions({
      processFetchContactDossierAction: 'dossier/fetchContactDossierList'
    })
  }
}
</script>
