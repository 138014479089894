<template>
  <div>
    <template v-if="editionMode">
      <div class="flex-grow lg:flex-shrink-0 lg:flex-grow-0">
        <BaseEditLabel
          v-if="label"
          :label="label"
          :required="required"
          class="my-3"
        />
        <div class="mt-1 lg:hidden">
          <div class="flex items-center">
            <div
              aria-hidden="true"
              class="inline-block h-12 w-12 flex-shrink-0 overflow-hidden rounded-full"
            >
              <img
                v-if="modelValue"
                :alt="description"
                :src="modelValue"
                class="h-full w-full rounded-full"
              />
              <img
                :alt="description"
                src="../assets/images/no_image.jpeg"
                class="h-full w-full rounded-full"
              />

            </div>

            <div class="ml-5 rounded-md shadow-sm">
              <div
                class="group relative flex items-center justify-center rounded-md border border-gray-300 px-3 py-2 focus-within:ring-2 focus-within:ring-sky-500 focus-within:ring-offset-2 hover:bg-gray-50"
              >
                <label
                  :for="`mobile-${name}-photo`"
                  class="pointer-events-none relative text-sm font-medium leading-4 text-gray-700"
                >
                  <span>{{ triggerLabel }}</span>
                  <span class="sr-only">{{ description }}</span>
                </label>
                <input
                  :id="`mobile-${name}-photo`"
                  :name="name"
                  class="absolute h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0"
                  type="file"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          :class="`relative hidden ${roundedClass} overflow-hidden lg:block`"
        >
          <img
            v-if="modelValue"
            :alt="description"
            :class="roundedClass"
            :src="modelValue"
          />
          <img
            v-else
            :alt="description"
            :class="roundedClass"
            src="../assets/images/no_image.jpeg"
          />
          <label
            :for="`desktop-${name}-photo`"
            class="absolute inset-0 flex h-full w-full items-center justify-center bg-black bg-opacity-75 text-sm font-medium text-white opacity-0 focus-within:opacity-100 hover:opacity-100"
          >
            <span>
              {{ triggerLabel }}
            </span>
          <FontAwesomeIcon v-if="!modelValue" class="cursor-pointer" :icon="['fal', 'plus-minus']" @click="updateFile" />
          <FontAwesomeIcon v-if="modelValue && manageMedia" class="cursor-pointer mr-1"  :icon="['fal', 'crop']" @click="editFile" />
          <FontAwesomeIcon v-if="modelValue" class="cursor-pointer mr-1"  :icon="['fal', 'trash']" @click="deleteFile" />
            <span class="sr-only">
              {{ description }}
            </span>
            <input
              v-if="!modelValue"
              :id="`desktop-${name}-photo`"
              :name="name"
              class="absolute inset-0 cursor-pointer rounded-md border-gray-300 opacity-0"
              type="file"

              @change="updateInput"
              :disabled="modelValue"
            />
          </label>
        </div>
      </div>
      <div
        v-for="(error, index) in errors"
        :key="index"
        class="form-help text-red-600"
      >
        {{ $t(error, { attribute: $t('attributes.' + name) }) }}
      </div>

    </template>
    <template v-else>
      <div class="">
        <BaseShowLabel
          v-if="label"
          :label="label"
          :required="required"
          :display-only-label="true"
        />
        <div
          class="overflow-hidden bg-theme-drop-area lg:block"
          :class="roundedClass"
        >
          <img
            v-if="modelValue"
            :alt="description"
            :src="modelValue"
            :class="`${roundedClass}`"
          />
          <img
            v-else
            :alt="description"
            src="../assets/images/no_image.jpeg"
            :class="`${roundedClass}`"
          />
        </div>

      </div>
    </template>

  </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import BaseButtonInput from "@c/addf-package/components/BaseInput/BaseButtonInput.vue";

export default {
  name: 'ImageFileInput',
  components: {
    BaseButtonInput,
    FontAwesomeIcon,
  },
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    modelValue: {
      type: Object,
      required: false,
      default: null
    },
    errors: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    triggerLabel: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    squared: {
      type: Boolean,
      required: false,
      default: false
    },
    manageMedia: {
      type: Boolean,
      required: false,
      default: false
    },
    type : {
      type: String,
      required: false,
    },
  },
  emits: ['update:modelValue', 'fileChange'],
  computed: {
    roundedClass() {
      return this.squared ? 'rounded-lg h-40' : 'rounded-full w-40 h-40'
    }
  },
  methods: {
    updateInput(event) {
      const file = event.target.files[0]
      this.$emit('fileChange', file)
      let url = URL.createObjectURL(file)
      this.$emit('update:modelValue', url)
    },
    updateFile(){
      document.getElementById(`desktop-${this.name}-photo`)?.click();
    },
    deleteFile(){
      this.$emit('fileChange', null)
      this.$emit('update:modelValue', null)
    },
    editFile(){
      alert('à implementer')
    }
  },
}
</script>
