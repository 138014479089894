<template>
  <div class="flex flex-row gap-2">
    <div v-if="searchValue || searchValue === ''">
      <BaseSearchInput v-model="searchValue" size="small" />
    </div>
    <div v-if="$slots['display-options-content']">
      <DisplayOptionsComponent>
        <template #display-options-content="{ close }">
          <slot name="display-options-content" :close="close" />
        </template>
      </DisplayOptionsComponent>
    </div>
    <div v-if="$slots['filter-content']">
      <FilterContainerComponent :count="countFilter ?? 0">
        <template #filter-content="{ close }">
          <slot name="filter-content" :close="close" />
        </template>
      </FilterContainerComponent>
    </div>
    <div v-if="$slots['other-cta']">
      <slot name="other-cta" />
    </div>
    <div class="flex flex-row gap-2">
      <BaseDropDownMenu :options="actionMenuOptions" />
      <div>
        <button
          v-if="need_save && $can('manage_dashboards')"
          type="button"
          class="btn btn-success rounded-md border px-2.5 py-2 text-sm font-semibold text-gray-900 shadow-sm"
          @click.prevent="saveDashboard"
        >
          <FontAwesomeIcon :icon="['fal', 'paper-plane']" />
        </button>
      </div>
    </div>
  </div>
  <DashboardModal
    :open="modalOpened"
    :dashboard_id="dashboard.id"
    :action="modalAction"
    :dashboard_template="dashboard"
    @close="modalOpened = false"
    @dashboard-changed="dashboardChanged"
  />
</template>

<script>
import FilterContainerComponent from './FilterContainerComponent.vue'
import DisplayOptionsComponent from '@c/Dashboard/Components/DisplayOptionsComponent.vue'
import DashboardModal from '@c/Dashboard/Components/DashboardModal.vue'
import BaseDropDownMenu from '@c/addf-package/components/dropdown/BaseDropDownMenu.vue'
import { mapActions } from 'vuex'
import BaseSearchInput from '@c/addf-package/components/BaseInput/BaseSearchInput.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import DashboardTypes from '@/assets/enums/dashboard/DashboardTypes'

export default {
  name: 'DashboardPanelControl',
  components: {
    FontAwesomeIcon,
    BaseSearchInput,
    BaseDropDownMenu,
    DashboardModal,
    DisplayOptionsComponent,
    FilterContainerComponent
  },
  props: {
    dashboard: {
      type: Object,
      required: true
    },
    countFilter: {
      type: Number,
      required: false,
      default: 0
    },
    searchValue: {
      type: String,
      required: false,
      default: null
    },
    needSaveLocked: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  emits: [
    'update:dashboard',
    'dashboard-changed',
    'update:searchValue',
    'exportFile'
  ],
  data() {
    return {
      modalOpened: false,
      modalAction: null,
      actionMenuOptions: [
        [
          {
            label: this.$t('button.save'),
            icon: 'paper-plane',
            gate: this.$can('manage_dashboards'),
            action: () => this.saveDashboard()
          },
          {
            label: this.$t('button.edit'),
            icon: 'edit',
            gate: this.$can('manage_dashboards'),
            action: () => this.editDashboard()
          },
          {
            label: this.$t('button.duplicate'),
            icon: 'clone',
            gate: this.$can('manage_dashboards'),
            action: () => this.duplicateDashboard()
          },
          {
            label: this.$t('global.export_list'),
            icon: 'file-export',
            gate:
              this.$can('manage_dashboards') &&
              [
                DashboardTypes.INVOICE,
                DashboardTypes.QUOTE,
                DashboardTypes.PURCHASE_ORDER,
                DashboardTypes.CREDIT_NOTE
              ].includes(this.dashboard?.type),
            action: this.handleExportFile
          },
          {
            label: this.$t('button.export'),
            icon: 'file-export',
            gate: [DashboardTypes.ANALYTICS].includes(this.dashboard?.type),
            action: this.excelExport
          },
          {
            label: this.$t('button.update_m', {
              var: this.$t(
                'enums.config.permissions.permissions_group.dashboard'
              )
            }),
            icon: 'rotate',
            gate: this.$can('manage_dashboards'),
            action: () => this.setFieldColumnDashboard()
          }
        ],
        [
          {
            label: this.$t('button.unsubscribe'),
            icon: 'trash',
            gate: true,
            bg_color: 'bg-red-500',
            txt_color: 'text-white',
            action: () => this.deleteDashboard()
          }
        ]
      ],
      need_save: false,
      isLoading: false
    }
  },
  watch: {
    searchValue(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('update:searchValue', newValue)
      }
    },
    'dashboard.columns': {
      handler(newValue, oldValue) {
        // je test si on a un dashboard avec des colonnes que l'on peut ajouter.
        // si oui et si la colonne n'a pas d'ID, alors on proose de save.
        if (
          [
            DashboardTypes.DASHBOARD,
            DashboardTypes.INVOICE,
            DashboardTypes.QUOTE,
            DashboardTypes.CREDIT_NOTE,
            DashboardTypes.PURCHASE_ORDER
          ].includes(this.dashboard.type) &&
          !this.needSaveLocked
        ) {
          this.need_save = true
        }
      },
      deep: true
    },
    'dashboard.filters': {
      handler(newValue, oldValue) {
        // je test si on a un dashboard avec des colonnes que l'on peut ajouter.
        // si oui et si la colonne n'a pas d'ID, alors on proose de save.
        if (
          [
            DashboardTypes.DASHBOARD,
            DashboardTypes.INVOICE,
            DashboardTypes.QUOTE,
            DashboardTypes.CREDIT_NOTE,
            DashboardTypes.PURCHASE_ORDER,
            DashboardTypes.PLANNING,
            DashboardTypes.AGENDA
          ].includes(this.dashboard.type) &&
          !this.needSaveLocked
        ) {
          this.need_save = true
        }
      },
      deep: true
    },
    'dashboard.sort': {
      handler(newValue, oldValue) {
        if (
          [
            DashboardTypes.DASHBOARD,
            DashboardTypes.INVOICE,
            DashboardTypes.QUOTE,
            DashboardTypes.CREDIT_NOTE,
            DashboardTypes.PURCHASE_ORDER,
            DashboardTypes.PLANNING,
            DashboardTypes.AGENDA
          ].includes(this.dashboard.type) &&
          !this.needSaveLocked
        ) {
          this.need_save = true
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      processUpdateTemplateDashboardByIdAction:
        'dashboard/updateTemplateDashboardById',
      processUnsubscribeToTemplateDashboardAction:
        'dashboard/unsubscribeToTemplateDashboard',
      processSetfieldToColumnTemplateDashboardByIdAction:
        'dashboard/setFieldToColumnDashboard',
      processRefreshSpecificInitialMetadataModelsAction:
        'metadata/refreshSpecificInitialMetadataModels',
      processFetchStatisticsForExportAction:
        'statistics/fetchStatisticsForExport'
    }),
    dashboardChanged(dashboard_template_id) {
      if (this.dashboard.id === dashboard_template_id) {
        this.$emit('dashboard-changed', dashboard_template_id)
      }
    },
    editDashboard() {
      this.modalAction = 'edit'
      this.modalOpened = true
    },
    duplicateDashboard() {
      this.modalAction = 'duplicate'
      this.modalOpened = true
    },
    handleExportFile() {
      this.isLoading = true
      this.$emit('exportFile')
    },
    excelExport() {
      this.isLoading = true
      this.processFetchStatisticsForExportAction({
        id: this.dashboard.id,
        successCallback: () => {
          this.isLoading = false
          // this.$h.toastify(this.$t('toasts.import_list_with_success'), {
          //   className: 'toastify-content success'
          // })
        },
        errorCallback: () => {
          this.$h.toastify(
            this.$t('global.error_when_import_file'),
            { className: 'toastify-content warning' },
            (this.isErrorModalOpen = true)
          )
        }
      })
    },
    saveDashboard() {
      this.processUpdateTemplateDashboardByIdAction({
        id: this.dashboard.id,
        data: this.dashboard
      }).then((response) => {
        this.$emit('update:dashboard', response.data.template_dashboard)
        this.$h.toastSuccess(this.$t('global.dashboard_saved'))
        this.need_save = false
      })
    },
    deleteDashboard() {
      this.processUnsubscribeToTemplateDashboardAction({
        template_dashboard_id: this.dashboard.id
      }).then((_response) => {
        this.processRefreshSpecificInitialMetadataModelsAction({
          model: 'template_dashboards'
        })
        this.$h.toastSuccess(this.$t('toasts.dashboard_unsubscribed'))
      })
    },
    setFieldColumnDashboard() {
      this.processSetfieldToColumnTemplateDashboardByIdAction({
        id: this.dashboard.id,
        data: this.dashboard
      }).then((response) => {
        this.$emit('update:dashboard', response.data.template_dashboard)
        this.$h.toastSuccess(this.$t('global.dashboard_saved'))
        this.need_save = false
      })
    }
  }
}
</script>

<style scoped></style>
