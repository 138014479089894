<template>
  <div v-if="errorKey" class="form-help-container">
    <div
      v-for="(error, index) in errors"
      :key="index"
      class="form-help text-red-600"
    >
      {{ errorText }}
      <!--      {{ Array.isArray(error) ? error.join(',') : error }}-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorContainer',
  props: {
    errors: {
      type: Object,
      required: true
    },
    errorKey: {
      type: String,
      required: true
    }
  },
  onErrorCaptured(err) {
    return false
  },
  computed: {
    errorText() {
      let text = Array.isArray(this.errors) ? this.errors.join(',') : this.errors;

      if (text.includes('attributes.*')) {
        const replacement = ' ' + this.$t(`attributes.${this.errorKey}`);
        text = text.replace('attributes.*', replacement);
      }
      if (text.includes('empty')) {
        text = text.replace('empty', this.$t('attributes.empty'));
      }

      return text;
    }
  },
}
</script>
