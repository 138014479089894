<template>
  <template v-if="editionMode">
    <div
      class="base-checkbox align-center flex rounded-md border border-slate-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600"
      :class="{
        'mt-2': !noMarginTop,
        'bg-primary-500': checked && !disabled,
        'text-white': checked,
        'text-gray-900': !checked,
        'cursor-pointer': !disabled,
        'disabled bg-gray-100': disabled
      }"
    >
      <label
        v-if="label"
        class="flex w-full flex-nowrap items-center gap-2 border-0 p-0 placeholder-gray-500 focus:ring-0 sm:text-sm"
        :class="{
          'cursor-pointer': !disabled,
          'cursor-context-menu': disabled
        }"
      >
        <FontAwesomeIcon :icon="['far', checked ? 'circle-check' : 'circle']" />
        <span class="block truncate">
          {{ label }}&nbsp;
          <span v-if="label && required" class="text-red-600">*</span>
        </span>
        <input
          v-if="value"
          v-model="modelValue"
          class="hidden"
          type="checkbox"
          :name="name"
          :disabled="disabled"
          :value="value"
        />
        <input
          v-else
          v-model="modelValue"
          class="hidden"
          type="checkbox"
          :name="name"
          :disabled="disabled"
        />
      </label>
    </div>
    <div
      v-for="(error, index) in errors"
      :key="index"
      class="form-help text-red-600"
    >
      {{ $t(error, { attribute: $t('attributes.' + name) }) }}
    </div>
  </template>
  <template v-else>
    <div
      class="align-center mt-2 flex rounded-md border border-slate-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600"
      :class="checked ? 'bg-primary text-white' : 'bg-white text-gray-900'"
    >
      <div
        class="flex w-full flex-nowrap items-center gap-2 border-0 p-0 placeholder-gray-500 focus:ring-0 sm:text-sm"
      >
        <FontAwesomeIcon :icon="['far', checked ? 'circle-check' : 'circle']" />
        <span>
          {{ label }}
        </span>
      </div>
    </div>
  </template>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'BaseCheckBox',
  components: { FontAwesomeIcon },
  props: {
    modelValue: {
      type: [String, Number, Boolean],
      required: true
    },
    value: {
      type: [String, Number, Boolean],
      required: false,
      default: undefined
    },
    label: {
      type: String,
      required: true
    },
    errors: {
      type: Object,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: false
    },
    noMarginTop: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['change', 'update:modelValue'],
  computed: {
    checked() {
      return this.value ? this.modelValue.includes(this.value) : this.modelValue
    }
  },
  watch: {
    modelValue(newValue) {
      this.$emit('update:modelValue', newValue)
      this.$emit('change', newValue)
    }
  }
}
</script>

<style>
.base-checkbox {
  user-select: none;
}

.base-checkbox.disabled {
  cursor: context-menu;
  color: #3d3d3d;
}
</style>
