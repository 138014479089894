/* eslint-disable camelcase */
import apiClient from '@/utils/apiClient'
import fileDownload from 'js-file-download'
import actionsVariante from './variante/actions'

const base_errors = {}

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

function errorSuccessCallback(payload) {
  if (payload.errorCallback) {
    payload.errorCallback()
  }
}

export default {
  ...actionsVariante,

  // region fetch
  async fetchAllItem({ commit }) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get('item')
      .then((response) => {
        commit('setItemList', response.data.items)
        commit('setItemMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchAllItem', reason)
      })
  },

  fetchItemsForCatalog: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get('item-catalog')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  getItemForCatalog: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`item-catalog/${payload.item_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  getItemForStockEdit: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`item/${payload.item_id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  async fetchItemByID({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`item/${payload.id}`)
      .then(async (response) => {
        await commit('setItem', response.data.item)
        await commit('setItemMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        console.error('fetchItemByID', reason)
      })
  },
  async fetchNewItem({ commit }) {
    commit('auth/setErrors', base_errors, { root: true })
    commit('setItem', null)
    await apiClient
      .get('model/new?model=item')
      .then((response) => {
        commit('setItem', response.data.item)
        commit('setItemMetadata', response.data.metadata)
      })
      .catch(async (reason) => {
        await console.error(reason)
      })
  },
  async fetchItemActivitiesByItemID({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    await apiClient
      .get(`item/fetch-activities/${payload.item_id}`)
      .then(async (response) => {
        await commit('setMetadataActivities', response.data.metadata)
        await commit('setItemActivity', response.data.activities)
      })
      .catch(async (reason) => {
        await console.error(reason)
      })
  },
  fetchItemByTerms: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/search-item-for-modal`, {
          params: {
            term: payload.term,
            specific_funeral_home_id: payload.funeral_home_id
          }
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  async fetchItemByCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .get(`/item-for-modal`, {
          params: {
            category: payload.category ?? '',
            specific_funeral_home_id: payload.funeral_home_id
          }
        })
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data.items)
          }
        })
        .catch(async (reason) => {
          reject(reason)
        })
    })
  },
  async fetchItemForCatalogByID({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .get(`item-catalog/${payload.id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  async fetchPackForInvoice({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .get(`/pack-for-modal`)
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data.packs)
          }
        })
        .catch(async (reason) => {
          reject(reject)
        })
    })
  },
  async fetchItemsDossierForInvoice({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      await apiClient
        .get(`/dossier/${payload.dossier_id}/item-dossier-for-modal`, {})
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data.items)
          }
        })
        .catch(async (reason) => {
          reject(reject)
        })
    })
  },
  async fetchItemPacks() {
    await apiClient
      .get(`item?category=packs`)
      .then(async (response) => {
        await console.log('fetchItemPacks', response)
      })
      .catch(async (reason) => {
        await console.error('fetchItemPacks', reason)
      })
  },
  // endregion

  //region export items
  async fetchAllItemsForExport({ commit }, payload) {
    await apiClient
      .post('/item/items-export')
      .then(async (response) => {
        window.open(response.data.url)
        processSuccessCallback(payload)
      })
      .catch((reason) => {
        console.error('errors', reason)
        commit('auth/setErrors', reason.response, { root: true })
      })
  },
  //endregion

  //region import items
  async importListItems({ commit }, payload) {
    commit('auth/setErrors', base_errors, { root: true })

    let formData = new FormData()
    formData.append('file', payload.file)
    await apiClient
      .post(`/item/items-import`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(async (response) => {
        if (response.status === 200) {
          if (response.data.errors) {
            commit('setErrorImport', response.data.errors)
            errorSuccessCallback(payload)
          } else if (response.data.items) {
            commit('setItemList', response.data.items)
            commit('setItemMetadata', response.data.metadata)
            processSuccessCallback(payload)
          }
        }
      })
      .catch(async (reason) => {
        await console.error('importListItems', reason)
      })
  },
  //endregion

  //region export items catalog
  exportPDFCatalog: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`/item/render/catalog`, { params: payload.data })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  //endregion

  // region store
  storeNewItem({ commit }, payload) {
    let formData = new FormData()

    formData.append('data', JSON.stringify(payload.item))

    if (payload.item.new_master_image_file) {
      formData.append(
        'new_master_image_file',
        payload.item.new_master_image_file
      )
    }

    commit('auth/setErrors', base_errors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .post('item', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          if (response.status === 201) {
            commit('setItem', response.data.item)
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region update
  updateItem({ commit }, payload) {
    let formData = new FormData()

    formData.append('data', JSON.stringify(payload.item))

    if (payload.item.new_master_image_file) {
      formData.append(
        'new_master_image_file',
        payload.item.new_master_image_file
      )
    }
    formData.append('_method', 'PUT')
    commit('auth/setErrors', base_errors, { root: true })
    return new Promise((resolve, reject) => {
      apiClient
        .post(`item/${payload.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          if (response.status === 201) {
            commit('setItem', response.data.item)
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  // region delete
  async deleteItemById({ commit }, payload) {
    await apiClient
      .delete(`item/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setItemList', response.data.items)
          commit('setItemMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('deleteItemById', reason)
      })
  },
  // endregion

  // region undoDelete
  async undoDeleteItemById({ commit }, payload) {
    await apiClient
      .get(`item/undo-delete/${payload.id}`)
      .then(async (response) => {
        if (response.status === 200) {
          commit('setItemList', response.data.items)
          commit('setItemMetadata', response.data.metadata)
          processSuccessCallback(payload)
        }
      })
      .catch(async (reason) => {
        console.error('undoDeleteItemById', reason)
      })
  },
  // endregion

  // region Item stock
  updateItemStockList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`item-stock-list-update/${payload.item_id}`, {
          location: payload.location,
          quantity: payload.quantity,
          purchase_price: payload.purchase_price
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  // endregion

  fetchItemStock({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`item/${payload.item_id}/stock`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchItemLocationSetting({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`item-location-setting`, {
          params: {
            location_id: payload.location_id,
            item_id: payload.item_id
          }
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  updateItemLocationSetting({ commit }, payload) {
    return new Promise((resolve, reject) => {
      apiClient
        .put(`item-location-setting/${payload.id}`, payload.item_stock_setting)
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}
