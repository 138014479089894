<template>
  <div class="flex min-h-0 flex-1 flex-col gap-4">
    <div class="flex shrink-0 flex-row justify-between">
      <div class="w-96">
        <BaseSearchInput v-model="search_term" />
      </div>
      <div class="flex-1"></div>
    </div>
    <div class="min-h-0 flex-1 overflow-y-auto">
      <table class="min-w-full divide-y divide-gray-300">
        <thead class="sticky top-0 bg-gray-50">
          <tr>
            <th
              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 capitalize-first sm:pl-6"
            >
              {{ $t('attributes.name') }}
            </th>
            <th
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 capitalize-first"
            >
              {{ $t('attributes.reference') }}
            </th>
            <th
              v-for="stockStatus in stockStatusesFiltered"
              :key="stockStatus.id"
              class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 capitalize-first"
            >
              {{ stockStatus.name }}
            </th>
            <th
              class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 capitalize-first"
            >
              {{ $t('attributes.stock_limit') }}
            </th>
            <th class="relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span class="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
          <tr v-for="item in itemsFiltered" :key="item.id">
            <td
              class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
            >
              <RouterLink
                :to="{
                  name: 'stock_item_route',
                  params: { itemId: item.id }
                }"
                class="block font-bold"
              >
                {{ item.name[$i18n.locale] }}
              </RouterLink>
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {{ item.ref }}
            </td>
            <th
              v-for="stockStatus in stockStatusesFiltered"
              :key="stockStatus.id"
              class="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500"
            >
              {{
                !locationId
                  ? '-'
                  : item.summary_stock_by_location?.[locationId]?.[
                      stockStatus.id
                    ] ?? '0'
              }}
            </th>
            <td
              class="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500"
            >
              {{
                !locationId
                  ? '-'
                  : item.location_settings_by_location?.[locationId]
                      ?.stock_limit ?? '--'
              }}
            </td>
            <td
              class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
            >
              <EditStockItemModal
                v-if="locationId"
                :item="item"
                :location-id="locationId"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseSearchInput from '@c/addf-package/components/BaseInput/BaseSearchInput.vue'
import EditStockItemModal from '@/views/stock/summary/component/EditStockItemModal.vue'
import SelectStorageLocation from '@c/InitialStateFormComponent/SelectStorageLocation.vue'

export default {
  name: 'UpdateStockComponent',
  components: {
    EditStockItemModal,
    BaseSearchInput
  },
  props: {
    locationId: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      items: [],
      search_term: ''
    }
  },
  computed: {
    ...mapGetters({
      stockStatuses: 'metadata/getMetadataStockStatuses'
    }),
    stockStatusesFiltered() {
      return this.stockStatuses.filter((stockStatus) =>
        ['ON_ORDER', 'AVAILABLE', 'TRANSIT'].includes(stockStatus.id)
      )
    },
    itemsFiltered() {
      let searchTermNormalized = this.search_term
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
      return this.items.filter((item) => {
        return (
          searchTermNormalized.length === 0 ||
          `${item.name[this.$i18n.locale]}${item.ref}`
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .includes(searchTermNormalized)
        )
      })
    }
  },
  watch: {
    selectedLocation(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        //this.getStockableItem()
      }
    }
  },
  mounted() {
    this.getStockableItem()
  },
  methods: {
    ...mapActions({
      processGetStockableItemAction: 'stock/getStockableItem'
    }),
    getStockableItem() {
      this.processGetStockableItemAction().then((response) => {
        this.items = response.items
      })
    }
  }
}
</script>
