<template>
  <ul>
    <!-- BEGIN: First Child -->
    <template v-for="(menu, menuKey) in formattedMenu">
      <li
        v-if="menu === 'devider'"
        :key="menu + menuKey"
        class="side-nav__devider my-6"
      />
      <li
        v-else-if="
          (menu.gate === undefined ||
            $can(menu.gate) ||
            (menu.meta && menu.meta.open_for_customer)) &&
          (menu.functionality === undefined ||
            this.$functionality.module.has(menu.functionality))
        "
        :key="menu + menuKey"
      >
        <a
          :href="
            menu.subMenu
              ? 'javascript:;'
              : router.resolve({ name: menu.pageName }).path
          "
          class="side-menu cursor-pointer select-none"
          :class="{
            'side-menu--active font-extrabold text-slate-300': menu.active,
            'side-menu--open': menu.activeDropdown,
            'justify-center px-3': collapsedMenu
          }"
          @click="linkTo(menu, router, $event)"
        >
          <div class="side-menu__icon">
            <FontAwesomeIcon
              :icon="['fal', menu.icon]"
              :fixed-width="true"
              size="lg"
            />
          </div>
          <transition>
            <div
              v-if="!collapsedMenu"
              class="side-menu__title transition-opacity duration-100 ease-linear"
            >
              <div class="inline-block capitalize-first">
                {{ $t(`navigation.${menu.title}`) }}
              </div>
              <div
                v-if="menu.subMenu"
                class="side-menu__sub-icon"
                :class="{ 'rotate-180 transform': menu.activeDropdown }"
              >
                <FontAwesomeIcon
                  :icon="['fal', 'chevron-down']"
                  :fixed-width="true"
                  size="sm"
                />
              </div>
            </div>
          </transition>
        </a>
        <!-- BEGIN: Second Child -->
        <transition @enter="enter" @leave="leave">
          <ul v-if="menu.subMenu && menu.activeDropdown">
            <li v-for="(subMenu, subMenuKey) in menu.subMenu" :key="subMenuKey">
              <a
                :href="
                  subMenu.subMenu
                    ? 'javascript:;'
                    : router.resolve({ name: subMenu.pageName }).path
                "
                class="side-menu cursor-pointer select-none"
                :class="{
                  'side-menu--active  font-extrabold text-slate-300 underline':
                    subMenu.active
                }"
                @click="linkTo(subMenu, router, $event)"
              >
                <div class="side-menu__icon">
                  <FontAwesomeIcon
                    :icon="['fal', subMenu.icon]"
                    :fixed-width="true"
                  />
                </div>
                <div
                  class="side-menu__title transition-opacity duration-100 ease-linear"
                  :class="collapsedMenu ? 'opacity-0' : ''"
                >
                  <div class="inline-block capitalize-first">
                    {{ $t(`navigation.${subMenu.title}`) }}
                  </div>
                  <div
                    v-if="subMenu.subMenu"
                    class="side-menu__sub-icon"
                    :class="{
                      'rotate-180 transform': subMenu.activeDropdown
                    }"
                  >
                    <FontAwesomeIcon
                      :icon="['fal', 'chevron-down']"
                      :fixed-width="true"
                      size="sm"
                    />
                  </div>
                </div>
              </a>
              <!-- BEGIN: Third Child -->
              <transition @enter="enter" @leave="leave">
                <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                  <li
                    v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu"
                    :key="lastSubMenuKey"
                  >
                    <a
                      :href="
                        lastSubMenu.subMenu
                          ? 'javascript:;'
                          : router.resolve({
                              name: lastSubMenu.pageName
                            }).path
                      "
                      class="side-menu"
                      :class="{
                        'side-menu--active': lastSubMenu.active
                      }"
                      @click="linkTo(lastSubMenu, router, $event)"
                    >
                      <div class="side-menu__icon">
                        <FontAwesomeIcon :icon="['fal', 'bolt']" size="sm" />
                      </div>
                      <div
                        class="side-menu__title transition-opacity duration-100 ease-linear"
                        :class="collapsedMenu ? 'opacity-0' : ''"
                      >
                        <div class="inline-block capitalize-first">
                          {{ $t(`navigation.${lastSubMenu.title}`) }}
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </transition>
              <!-- END: Third Child -->
            </li>
          </ul>
        </transition>
        <!-- END: Second Child -->
      </li>
    </template>
    <!-- END: First Child -->
  </ul>
</template>
<script>
import { computed, onMounted, ref, watch } from 'vue'
import {
  enter,
  leave,
  linkTo,
  nestedMenu
} from '@/views/layouts/main-layout/index'
import { helper as $h } from '@u/helper'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { useStore } from '@/store'
import { useRoute, useRouter } from 'vue-router'
import { mapGetters } from 'vuex'

export default {
  name: 'MainMenu',
  components: { FontAwesomeIcon },
  setup() {
    const formattedMenu = ref([])

    const router = useRouter()
    const store = useStore()
    const route = useRoute()
    const sideMenu = computed(() => {
      return nestedMenu(store.state.sideMenu.menu, route)
    })
    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value)
      }
    )
    onMounted(() => {
      formattedMenu.value = $h.toRaw(sideMenu.value)
    })
    return {
      formattedMenu,
      router,
      linkTo,
      enter,
      leave
    }
  },
  computed: {
    ...mapGetters({
      collapsedMenu: 'sideMenu/collapsedMenu'
    })
  }
}
</script>
