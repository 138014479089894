<template>
  <BaseButton
    :title="$t('button.edit')"
    size="extra-small"
    @click="modalOpened = true"
  />
  <BaseCenteredModal
    v-model:is-modal-open="modalOpened"
    :title="item.name[$i18n.locale]"
    size="medium"
  >
    <template v-if="locationId" #subtitle>
      {{ $t('attributes.place') }}: {{ labelSelectedLocation }}
    </template>
    <template #body>
      <div class="flex w-full flex-col gap-3">
        <div v-if="!locationId">
          <SelectStorageLocation
            v-model="selectedLocationId"
            error-key="location"
            :edition-mode="true"
          />
        </div>
        <div class="flex flex-col gap-2 rounded border p-3 pt-2">
          <h6 class="text-base font-semibold capitalize-first">
            {{ $t('global.stock') }}
          </h6>
          <div class="grid grid-cols-1 gap-2 md:grid-cols-2">
            <div>
              <BaseShowEditInput
                v-model="quantityAvailable"
                :disabled="!editQuantityAvailable"
                :min="0"
                type="number"
              />
            </div>
            <div class="flex flex-row justify-end gap-2">
              <BaseButton
                v-if="editQuantityAvailable"
                :title="$t('button.save')"
              />
              <BaseButton
                :title="
                  $t(editQuantityAvailable ? 'button.cancel' : 'button.edit')
                "
                theme-color="bg-secondary text-secondary-contrast"
                @click="handleEditCancel"
              />
            </div>
            <div v-if="editQuantityAvailable" class="col-span-2">
              <InfoPanel
                v-if="originalQuantityAvailable < quantityAvailable"
                :title="'Info'"
                >augmentez le stock créera des items stocks unique avec leur
                propre identifiant.</InfoPanel
              >
              <InfoPanel
                v-else-if="originalQuantityAvailable > quantityAvailable"
                type="warning"
                :title="'Attention'"
              >
                diminuez le stock, mettra des éléments en avarie.
              </InfoPanel>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-2 rounded border p-3 pt-2">
          <h6 class="text-base font-semibold capitalize-first">
            {{ $t('global.order') }}
          </h6>
          <div v-if="localItem" class="grid grid-cols-1 gap-2 md:grid-cols-2">
            <div
              v-for="supplier_order in localItem.supplier_orders"
              :key="supplier_order.id"
              class="cursor-pointer rounded border p-2 text-left"
              :class="[
                !newSupplierOrder &&
                selectedSupplierOrderId === supplier_order.id
                  ? 'border-primary shadow'
                  : ''
              ]"
              @click="() => handleButtonSelectExistingOrder(supplier_order.id)"
            >
              <h3 class="font-semibold">
                {{ supplier_order.reference }}
              </h3>
              <p class="text-xs"># {{ supplier_order.id }}</p>
            </div>
            <div
              class="flex cursor-pointer items-center justify-center rounded p-2"
              :class="[
                newSupplierOrder
                  ? 'border border-primary shadow'
                  : 'border-2 border-dashed'
              ]"
              @click="handleButtonNewOrder"
            >
              <h3 class="font-semibold">
                {{ $t('button.add_order') }}
              </h3>
            </div>
          </div>
          <div
            v-if="orderSelected"
            class="mt-4 grid grid-cols-1 gap-2 md:grid-cols-2"
          >
            <div>
              <BaseShowEditInput
                v-model="quantityToOrder"
                :min="0"
                type="number"
                :label="$t('attributes.quantity')"
              />
            </div>
            <div v-if="newSupplierOrder">
              <SelectStorageLocation
                v-model="newSupplierOrderLocationId"
                error-key="location"
                :edition-mode="true"
                required
              />
            </div>
            <div class="col-span-2 flex justify-end">
              <BaseButton
                :title="$t('button.order')"
                :disabled="!canOrder"
                @click="handleOrderItem"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </BaseCenteredModal>
</template>
<script>
import BaseCenteredModal from '@c/addf-package/components/modal/BaseCenteredModal.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import SelectStorageLocation from '@c/InitialStateFormComponent/SelectStorageLocation.vue'
import { mapActions, mapGetters } from 'vuex'
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'
import InfoPanel from '@ui/InfoPanel.vue'

export default {
  name: 'EditStockItemModal',
  components: {
    InfoPanel,
    BaseShowEditInput,
    SelectStorageLocation,
    BaseButton,
    BaseCenteredModal
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    locationId: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      localItem: null,
      modalOpened: false,
      selectedLocationId: null,
      editQuantityAvailable: false,
      originalQuantityAvailable: 0,
      quantityAvailable: 0,
      newSupplierOrder: false,
      newSupplierOrderLocationId: null,
      selectedSupplierOrderId: null,
      quantityToOrder: 0
    }
  },
  computed: {
    ...mapGetters({
      locations: 'metadata/getLocationsMetadata'
    }),
    labelSelectedLocation() {
      return (
        this.locations.find((location) => location.id === this.locationId)
          ?.name ?? '---'
      )
    },
    orderSelected() {
      return this.newSupplierOrder || this.selectedSupplierOrderId !== null
    },
    canOrder() {
      return (
        (this.newSupplierOrder &&
          this.quantityToOrder > 0 &&
          this.newSupplierOrderLocationId) ||
        (!this.newSupplierOrder &&
          this.selectedSupplierOrderId &&
          this.quantityToOrder > 0)
      )
    }
  },
  watch: {
    modalOpened(newValue, oldValue) {
      if (newValue) {
        this.getItemForStockEdit()
      }
    }
  },
  mounted() {
    if (this.locationId) {
      this.selectedLocationId = this.locationId
    }
  },
  methods: {
    ...mapActions({
      processFetchItemStockAction: 'item/fetchItemStock'
    }),
    getItemForStockEdit() {
      this.processFetchItemStockAction({
        item_id: this.item.id
      }).then((response) => {
        this.localItem = response.item
        this.originalQuantityAvailable = this.quantityAvailable =
          response.item.summary_stock_by_location?.[this.selectedLocationId]?.[
            'AVAILABLE'
          ] ?? 0
      })
    },
    handleEditCancel() {
      if (this.editQuantityAvailable) {
        this.quantityAvailable = this.originalQuantityAvailable
        this.editQuantityAvailable = false
      } else {
        this.editQuantityAvailable = true
      }
    },
    handleButtonNewOrder() {
      this.selectedSupplierOrderId = null
      this.newSupplierOrder = !this.newSupplierOrder
    },
    handleButtonSelectExistingOrder(supplier_order_id) {
      this.newSupplierOrder = false
      if (this.selectedSupplierOrderId === supplier_order_id) {
        this.selectedSupplierOrderId = null
        return
      }
      this.selectedSupplierOrderId = supplier_order_id
    },
    handleOrderItem() {
      if (this.newSupplierOrder) {
        // process new order
        this.$h.toastWarning('process new order')
      } else {
        // process add item to existing order
        this.$h.toastWarning('process add item to existing order')
      }
    }
  }
}
</script>

<style scoped></style>
