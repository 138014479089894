<template class="overflow-y-scroll">
  <div
    v-if="contact && initialMetadata"
    :class="[
      wide ? 'grid grid-cols-1 gap-x-3 md:grid-cols-2' : 'grid grid-cols-1'
    ]"
  >
    <div class="grid grid-cols-1 gap-2">
      <!--region company-->
      <div>
        <WalkmanButton
          v-model="localContact.is_company"
          :label="$t('attributes.company')"
          :options="[
            { value: true, label: $t('enums.yesNo.1') },
            { value: false, label: $t('enums.yesNo.0') }
          ]"
          name="is_company"
        />
      </div>
      <div v-if="localContact.is_company">
        <BaseShowEditInput
          v-model="localContact.company_name"
          :label="$t('attributes.denomination')"
          :placeholder="$t('attributes.denomination')"
          name="company_name"
          :edition-mode="true"
        />
      </div>
      <div v-if="localContact.is_company">
        <BaseShowEditInput
          v-model="localContact.tva"
          :label="$t('attributes.TVA')"
          :placeholder="$t('attributes.TVA')"
          name="tva"
          :edition-mode="true"
        />
      </div>
      <!--endregion-->

      <!--region agences -->
      <div :class="[localContact.is_company ? 'mt-2' : '']">
        <SelectFuneralHomesForContactForm
          :contact="localContact"
          @add-favorite-on-funeral-home="handleAddFavoriteOnFuneralHome"
          @select-funeral-home-for-contact="handleSelectFuneralHomeForContact"
        />
      </div>
      <!--endregion-->

      <!--region informations-->
      <div v-if="!localContact.is_company">
        <SelectCivility
          v-model="localContact.civility"
          :edition-mode="true"
          :errors="errors?.civility"
        />
      </div>
      <div>
        <SelectContactRole
          v-model="localContact.role"
          edition-mode
          :label="$t('attributes.role')"
          name="role"
          :errors="errors.role"
        />
      </div>
      <div v-if="!contact.is_company">
        <BaseShowEditInput
          v-model="localContact.first_name"
          :label="$t('attributes.first_name')"
          :placeholder="$t('attributes.first_name')"
          :edition-mode="true"
          name="first_name"
          :errors="errors.first_name"
          :required="true"
        />
      </div>
      <div v-if="!contact.is_company">
        <BaseShowEditInput
          v-model="localContact.last_name"
          :label="$t('attributes.last_name')"
          :placeholder="$t('attributes.last_name')"
          :edition-mode="true"
          name="last_name"
          :errors="errors.last_name"
          :required="true"
        />
      </div>
      <!--endregion-->

      <!--region adresse-->
      <BaseShowEditAddress
        v-model:country="localContact.country"
        v-model:city-id="localContact.city_id"
        v-model:current-city-object="localContact.city_object"
        v-model:zip="localContact.zip"
        v-model:city="localContact.city"
        v-model:extra-street="localContact.extra_street"
        v-model:street="localContact.street"
        :edition-mode="true"
        :errors="errors"
      />
      <!--endregion-->

      <!--region contact-->
      <div>
        <SelectLanguages
          v-model="localContact.language"
          :edition-mode="true"
          :errors="errors?.language"
        />
      </div>
      <div>
        <BaseShowEditInput
          v-model="localContact.email"
          :label="$t('attributes.email')"
          :placeholder="$t('attributes.email')"
          :edition-mode="true"
          :errors="errors.email"
          name="email"
        />
      </div>
      <div>
        <BaseShowEditPhoneInput
          v-model:countryValue="localContact.phone_country"
          v-model:numberValue="localContact.phone_field"
          :label="$t('attributes.phone')"
          :edition-mode="true"
          name="phone"
          :metadata="initialMetadata.enums"
          :errors="errors"
        />
      </div>
      <div>
        <BaseShowEditPhoneInput
          v-model:countryValue="localContact.mobile_country"
          v-model:numberValue="localContact.mobile_field"
          :label="$t('attributes.mobile')"
          :edition-mode="true"
          name="mobile"
          :metadata="initialMetadata.enums"
          :errors="errors"
        />
      </div>
      <div>
        <BaseShowEditPhoneInput
          v-if="isWikiContact"
          v-model:countryValue="localContact.fax_country"
          v-model:numberValue="localContact.fax_field"
          :label="$t('attributes.fax')"
          :edition-mode="true"
          name="fax"
          :metadata="initialMetadata.enums"
          :errors="errors"
        />
      </div>
      <!--endregion-->
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { Country } from '@/assets/enums'
import SelectLanguages from '@c/InitialStateFormComponent/Enums/SelectLanguages.vue'
import SelectCivility from '@c/InitialStateFormComponent/Enums/SelectCivility.vue'
import SelectFuneralHomesForContactForm from '@ui/modal/contact/components/SelectFuneralHomesForContactForm.vue'
import BaseShowEditAddress from '@c/BaseShowEditAddress.vue'
import WalkmanButton from '@c/WalkmanButton.vue'
import BaseShowEditInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditInput.vue'
import SelectContactRole from '@c/InitialStateFormComponent/Enums/SelectContactRole.vue'
import BaseShowEditPhoneInput from '@c/addf-package/components/BaseShowEditInput/BaseShowEditPhoneInput.vue'

export default {
  name: 'ContactForm',
  components: {
    SelectFuneralHomesForContactForm,
    BaseShowEditPhoneInput,
    SelectContactRole,
    BaseShowEditInput,
    WalkmanButton,
    BaseShowEditAddress,
    SelectCivility,
    SelectLanguages
  },
  props: {
    contact: {
      type: Object,
      required: true
    },
    showContact: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    errors: {
      type: Array,
      required: false,
      default: () => []
    },
    wide: {
      type: Boolean,
      required: false,
      default: false
    },
    roleIsDisabled: {
      type: Boolean,
      required: false,
      default: null
    },
    isWikiContact: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:contact'],
  data() {
    return {
      wiki_city: null,
      Country: Country
    }
  },
  computed: {
    ...mapGetters({
      initialMetadata: 'metadata/getInitialMetadata'
    }),
    localContact: {
      get() {
        return this.contact
      },
      set(value) {
        this.$emit('update:contact', value)
      }
    }
  },
  methods: {
    handleAddFavoriteOnFuneralHome(value) {
      if (Object.keys(this.localContact.funeral_homes_id).includes(value.id)) {
        this.localContact.funeral_homes_id[value.id].favorite =
          !this.localContact.funeral_homes_id[value.id]?.favorite
      }
    },
    handleSelectFuneralHomeForContact(value) {
      if (this.localContact.funeral_homes_id[value.id]) {
        delete this.localContact.funeral_homes_id[value.id]
      } else {
        this.localContact.funeral_homes_id[value.id] = {
          favorite: false
        }
      }
    }
  }
}
</script>
