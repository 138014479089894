<template>
  <Popover v-slot="{ open, close }">
    <Float
      :offset="10"
      :auto-placement="{
        autoAlignment: true,
        allowedPlacements: ['bottom-end', 'top-end']
      }"
    >
      <PopoverButton
        ref="openFilterButton"
        :class="
          open
            ? 'border border-primary-400 dark:border-primary-500'
            : 'border border-slate-300 text-opacity-90 dark:border-slate-600 '
        "
        class="group inline-flex items-center justify-between gap-2 rounded-md px-3 py-2 font-medium text-slate-600 hover:text-opacity-100 dark:border-white dark:bg-slate-700 dark:text-gray-300"
      >
        <FontAwesomeIcon
          v-if="isLoading"
          :icon="['fal', 'spinner-third']"
          class="animate-spin"
        />
        <FontAwesomeIcon v-else :icon="['fal', 'filter']" />
        <span class="text-xs font-medium capitalize-first">
          {{ $t('global.filter') }}
        </span>
        <span
          v-if="count && count > 0"
          class="-my-1 inline-flex items-center rounded-md border bg-blue-500 px-1.5 py-0.5 text-xs font-medium text-white"
        >
          {{ count }}
        </span>
        <FontAwesomeIcon
          class="h-4 transition duration-150 ease-in-out group-hover:text-opacity-80"
          :class="open ? '' : 'text-opacity-70'"
          :icon="['fas', 'caret-down']"
        ></FontAwesomeIcon>
      </PopoverButton>
      <PopoverPanel
        class="z-90 w-screen max-w-sm transform rounded-lg px-4 dark:border-white dark:bg-slate-800 dark:text-gray-300 sm:px-0 lg:max-w-4xl"
      >
        <div
          class="flex flex-col rounded-lg bg-white shadow-xl ring-1 ring-black ring-opacity-5 dark:rounded-lg dark:border-white dark:bg-slate-800 dark:text-gray-300"
        >
          <!--#region headLine -->
          <div class="relative flex flex-row items-center gap-2 px-3 py-2">
            <h1 class="flex-1 text-xl font-medium capitalize">
              {{ $t('global.advanced_filters') }}
            </h1>
          </div>
          <!--#endregion -->
          <div>
            <slot name="filter-content" :close="close" />
          </div>
        </div>
      </PopoverPanel>
    </Float>
  </Popover>
</template>
<script>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Float } from '@headlessui-float/vue'

export default {
  name: 'FilterContainerComponent',
  components: { Float, FontAwesomeIcon, PopoverPanel, Popover, PopoverButton },
  props: {
    count: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {},
  methods: {}
}
</script>
