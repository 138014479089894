<template>
  <BaseSlideOverModal v-model:is-modal-open="localOpen" width="2xl">
    <template #cta>
      <div class="flex h-full gap-x-1">
        <template v-if="internalTab === 'list'">
          <BaseButton
            :title="$t('button.new')"
            icon="plus"
            @click="internalTab = 'form'"
          />
        </template>
        <template v-else>
          <BaseButton
            v-if="!forceForm"
            :title="$t('button.back')"
            color="dark"
            icon="long-arrow-left"
            @click="handleBackButton"
          />
          <BaseButton
            :title="$t('button.save')"
            icon="paper-plane"
            @click="$refs.contactForm.save()"
          />
        </template>
      </div>
    </template>
    <template #body>
      <div
        class="scrollbar-custom flex h-full w-full flex-col justify-between overflow-x-visible overflow-y-scroll px-1"
      >
        <SelectContactList
          v-if="internalTab === 'list'"
          ref="contactList"
          v-model:contact-id="localContactId"
          :role-id="roleId"
          :funeral-home-id="funeralHome"
          :role-name="roleName"
          :icon="icon"
          @edit-contact="handleEditContact"
        />
        <SelectContactForm
          v-else
          ref="contactForm"
          :role-id="roleId"
          :contact-id="localContactId"
          @contact-updated="handleContactUpdated"
        />
      </div>
    </template>
  </BaseSlideOverModal>
</template>

<script>
import SelectContactList from '@ui/modal/contact/components/SelectContactList'
import SelectContactForm from '@ui/modal/contact/components/SelectContactForm'
import BaseSlideOverModal from '@c/addf-package/components/modal/BaseSlideOverModal.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'

export default {
  name: 'SelectContactModal',
  components: {
    BaseButton,
    BaseSlideOverModal,
    SelectContactForm,
    SelectContactList
  },
  props: {
    open: {
      type: Boolean,
      required: true
    },
    contactId: {
      type: String,
      required: false,
      default: null
    },
    roleId: {
      type: String,
      required: false,
      default: null
    },
    funeralHome: {
      type: String,
      required: false,
      default: null
    },
    roleName: {
      type: String,
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    forceForm: {
      type: Boolean,
      required: false,
      default: false
    },
    autoClose: {
      type: Boolean,
      required: false,
      default: false
    },
    refreshFunction: {
      type: Function,
      required: false,
      default: null
    }
  },
  emits: ['close', 'update:contactId', 'change', 'update:open'],
  data() {
    return {
      internalTab: 'list',
      disableAutoClose: false
    }
  },
  computed: {
    localContactId: {
      get() {
        return this.contactId
      },
      set(newValue) {
        if (this.autoClose && !this.disableAutoClose) {
          this.$emit('change', newValue)
          this.localOpen = false
        }
        this.$emit('update:contactId', newValue)
      }
    },
    localOpen: {
      get() {
        return this.open
      },
      set(value) {
        this.$emit('update:open', value)
      }
    }
  },
  watch: {
    open(newValue, oldValue) {
      if (newValue) {
        if (this.forceForm) {
          this.internalTab = 'form'
        } else {
          this.internalTab = 'list'
        }
      }
    }
  },
  methods: {
    handleContactUpdated(contact) {
      if (this.forceForm) {
        this.localOpen = false
      } else {
        this.internalTab = 'list'
      }
    },
    handleEditContact(contact_id) {
      this.disableAutoClose = true
      this.localContactId = contact_id
      this.internalTab = 'form'
    },
    handleBackButton() {
      this.disableAutoClose = false
      this.internalTab = 'list'
    }
  }
}
</script>
