<template>
  <template v-if="editionMode">
    <!--#region wysiwyg -->
    <div class="">
      <BaseWysiwyg
        ref="editorForm"
        v-model="modelValue"
        :display-format-button="displayFormatButton"
        name="text"
        :suggestion-variables="allVariables"
        :errors="errors"
        :label="label"
        @change="updateModelValue"
      >
        <template #cta>
          <Popover v-slot="{ open }" class="relative">
            <PopoverButton
              :class="open ? '' : 'text-opacity-90'"
              class="rounded border border-gray-300 px-2 py-1 leading-none"
            >
              <span>@</span>
            </PopoverButton>

            <transition
              enter-active-class="transition duration-200 ease-out"
              enter-from-class="translate-y-1 opacity-0"
              enter-to-class="translate-y-0 opacity-100"
              leave-active-class="transition duration-150 ease-in"
              leave-from-class="translate-y-0 opacity-100"
              leave-to-class="translate-y-1 opacity-0"
            >
              <PopoverPanel
                class="absolute right-0 z-10 mt-1 w-screen max-w-sm transform px-4 sm:max-w-xl sm:px-0"
              >
                <div
                  class="flex h-64 flex-col rounded-md bg-white p-2 shadow-lg ring-1 ring-black ring-opacity-5"
                >
                  <div class="shrink-0 pb-1">
                    <div
                      class="inline-flex flex-row justify-around divide-x divide-gray-500 rounded border border-gray-500"
                    >
                      <button
                        v-for="group in metadata.variables"
                        :key="group.key"
                        class="px-2 text-center capitalize-first"
                        :class="{
                          'bg-primary-500 text-white':
                            selectedGroup === group.key
                        }"
                        @click="selectedGroup = group.key"
                      >
                        {{ $t(group.label) }}
                      </button>
                    </div>
                  </div>

                  <div
                    v-if="selectedGroup"
                    class="relative mt-1 flex-1 overflow-y-scroll"
                  >
                    <template
                      v-for="subgroup in metadata.variables.find(
                        (item) => item.key === selectedGroup
                      ).subgroup"
                      :key="subgroup.key"
                    >
                      <h5
                        class="sticky top-0 bg-white font-bold capitalize-first"
                      >
                        {{ $t(subgroup.label) }}
                      </h5>
                      <div class="flex flex-wrap gap-1">
                        <template
                          v-for="variable in subgroup.variables"
                          :key="variable.key"
                        >
                          <button
                            class="rounded border border-gray-700 px-1 py-0.5 text-xs"
                            @click="variableAssigned(variable)"
                            v-html="`@{{${variable.value}}}`"
                          ></button>
                        </template>
                      </div>
                    </template>
                  </div>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>
        </template>
      </BaseWysiwyg>
    </div>
    <!--#endregion -->
    <div
      v-for="(error, index) in errors"
      :key="index"
      class="form-help text-red-600"
    >
      {{ $t(error, { attribute: $t('attributes.' + name) }) }}
    </div>
  </template>
  <template v-else>
    <BaseShowLabel :label="label" :display-only-label="true" />
    <div
      v-if="modelValue"
      class="box bg-gray-200 p-5 shadow"
      v-html="modelValue"
    ></div>
    <div v-else class="">
      <p>-</p>
    </div>
  </template>
</template>

<script>
import { mapActions } from 'vuex'
import BaseWysiwyg from '@c/addf-package/components/Wysiwyg/BaseWysiwyg.vue'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

export default {
  name: 'BaseShowEditVariableWysiwyg',
  components: { BaseWysiwyg, Popover, PopoverPanel, PopoverButton },
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
    modelValue: {
      type: String,
      required: true
    },
    displayFormatButton: {
      type: Boolean,
      required: false,
      default: true
    },
    errors: {
      type: [Array, Object],
      required: false,
      default: null
    },
    metadata: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      messageVariables: [],
      eventVariables: [],
      isEventVariable: false,
      selectedGroup: null
    }
  },
  computed: {
    allVariables() {
      let allVariables = []
      for (let i in this.metadata.allVariables) {
        allVariables.push(this.metadata.allVariables[i])
      }
      return allVariables
    }
  },
  methods: {
    ...mapActions({
      fetchNewMessageText: 'messageText/fetchNewMessageText',
      fetchNewMessageTextById: 'messageText/fetchMessageTextById',
      addMessageText: 'messageText/storeMessageText',
      editMessageText: 'messageText/updateMessageText'
    }),
    updateModelValue(payload) {
      this.$emit('update:modelValue', payload)
      this.$emit('change', payload)
    },
    submitMessageText() {
      if (!isNaN(this.message_text_load_id)) {
        this.editMessageText({
          messageText: this.model,
          successCallback: () => {
            this.$h.toastify(
              this.$t('toasts.updated_m', {
                var: this.$t('global.message_text')
              }),
              { className: 'toastify-content success' }
            )
          }
        })
      } else {
        this.addMessageText({
          messageText: this.model,
          successCallback: () => {
            this.$h.toastify(
              this.$t('toasts.created_m', {
                var: this.$t('global.message_text')
              }),
              { className: 'toastify-content success' }
            )
          }
        })
      }
    },
    openVariablesGroup(group, index) {
      if (index !== 'Event') {
        this.isEventVariable = false
        this.messageVariables = group
      } else {
        this.isEventVariable = true
        this.eventVariables = group
        this.messageVariables = []
      }
    },
    variableAssigned(variable) {
      this.$refs.editorForm.setTextAtPosition(variable)
    }
  }
}
</script>
