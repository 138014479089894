import apiClient from '@u/apiClient'

export default {
  getUsage({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiClient
        .get('/funeral-manager/usage')
        .then((response) => {
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  }
}
